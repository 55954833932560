// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';


/** 获取当前的用户 GET /api/currentUser */
export async function getBusinessDetail(id: string) {
  return request<{}>('/base/business/detail-by-id?id=' + id, {
    method: 'GET',
  }).then((aa) => {
    return aa;
    // return aa?.map((item: any) => {
    //   return {...item, label: item.businessName, value: item.businessCode}
    // })
  });
}

/** 获取当前的用户 GET /api/currentUser */
export async function getSystemDetail(id: string) {
  return request<{}>('/base/system/detail-by-id?id=' + id, {
    method: 'GET',
  }).then((aa) => {
    return aa;
    // return aa?.map((item: any) => {
    //   return {...item, label: item.businessName, value: item.businessCode}
    // })
  });
}

/** 取消会议 */
export async function cancelMeeting(meetingId: string) {
  return request<{}>('/issue-review/delete-review-meeting?meetingId=' + meetingId, {
    method: 'GET',
  });
}

/** 获取会议详情 */
export async function getMeetingDetail(meetingId: string) {
  return request<{}>('/issue-review/review-meeting-detail?meetingId=' + meetingId, {
    method: 'GET',
  });
}

/** 获取国家 */
export async function listCountry() {
  return request<{}>('/it-support/list-by-level?level=country', {
    method: 'GET',
  });
}

/** 获取国家2 */
export async function listCountryMatrix() {
  return request<API.ImileResponse<any>>(
    '/matrix/list-by-level?matrixName=Tech Support List&levelName=country',
    {
      method: 'GET',
    },
  );
}

/** 获取矩阵子节点数据 */
export async function listChildNode(param: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.Tag>>('/matrix/list-child-node', {
    data: {
      ...param,
    },
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取技术支持矩阵下指定国家的所有技术支持+区域负责人 */
export async function listCountryItSupport(param: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.Tag>>('/matrix/get-country-it', {
    data: {
      ...param,
    },
    method: 'POST',
    ...(options || {}),
  });
}

/** 需求下拉列表 */
export async function listSelectIssue(param: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue/showList', {
    data: {
      currentPage: 1,
      showCount: 50,
      ...param,
    },
    method: 'POST',
    ...(options || {}),
  });
}

/** 查需求基础信息 */
export async function listIssueBasicInfo(param: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue/list-issue-basic-info', {
    data: {
      ...param,
    },
    method: 'POST',
    ...(options || {}),
  });
}

/** 标签列表 */
export async function addLabelTag(
  data: {
    labelNameCn: string;
    labelNameEn: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/base/label/add', {
    method: 'POST',
    data: {
      ...data,
    },
    ...(options || {}),
  });
}

export async function pagedIssuesPool(
  params: {
    current?: number;
    pageSize?: number;
    systemId?: string;
    productManagerCode?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.IssueList>>('/issue-v2/page-issue-pool', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      // businessCode: options?.businessName?.[0],
      ...params,
    },
    ...(options || {}),
  });
}
//需求日志记录
export async function listIssueOpLog(
  params: {
    relationType?: string;
    relationId?: number;
    opType?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.opLog[]>>('/op-log/group-by-op-time', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 添加备注 */
export async function addRemark(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/op-log/add-remark', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 添加评论 */
export async function addComment(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/op-log/add-comment', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 删除评论 */
export async function delCommonent(commentId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/op-log/remove-comment?id=' + commentId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 创建需求 */
export async function createIssue(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/create-issue', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 更新需求 */
export async function updIssue(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/update-issue', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 获取暂存需求 */
export async function getStagingIssueDetail(options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.IssueDetail>>('/issue-v2/user/staging-issue-detail', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 关注需求 */
export async function followIssue(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/userOperation/switchFollow', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 创建或修改规划 */
export async function createOrUpdPlan(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/create-or-update_plan', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 发起业务评审 */
export async function createBusinessReview(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/business-review', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 发起会议 */
export async function createMeetingReview(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/create-review-meeting', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 修改会议 */
export async function updMeetingReview(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/update-review-meeting', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

// /** 发起研发评审（创建评审） */
// export async function createDevelopReview(body: {}, options?: { [key: string]: any }) {
//   return request<API.ImileResponse<any>>('/issue-review/develop-review', {
//     method: 'POST',
//     data: {
//       ...body,
//     },
//     ...(options || {}),
//   });
// }

/** 创建评审 */
export async function createReview(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/create-review', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 更新评审 */
export async function updReview(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/update-review', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 填写评审纪要 */
export async function fillReviewSummary(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/fill-review-summary', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

// /** 更新研发评审（更新评审） */
// export async function updDevelopReview(body: {}, options?: { [key: string]: any }) {
//   return request<API.ImileResponse<any>>('/issue-review/update-review', {
//     method: 'POST',
//     data: {
//       ...body,
//     },
//     ...(options || {}),
//   });
// }
/** 更新&重新发起评审 */
export async function anewDevelopReview(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/anew-develop-review', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/**  */
export async function changeIssuePlan(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-plan/change-issue-plan', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 修改需求状态 */
export async function progressChange(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue/progressChange-v2', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 获取需求详情 */
export async function getIssueDetail(id: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.IssueDetail>>('/issue-v2/detail/issueSn?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取评审确认情况 */
export async function getReviewConfirmDetails(reviewId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>(
    '/issue-review/review-confirm-details?reviewId=' + reviewId,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 获取评审详情 */
export async function getReviewDetail(reviewId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/review-detail?id=' + reviewId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取评审关联详情 */
export async function getReviewRelationDetails(reviewId, reviewType) {
  return request<API.ImileResponse<any>>(
    '/issue-review/review-relation-details?reviewId=' + reviewId + '&reviewType=' + reviewType,
    {
      method: 'GET',
    },
  );
}

/** 取消、删除评审 */
export async function cancelReview(reviewId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/cancel-review?reviewId=' + reviewId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 完成评审 */
export async function completeReview(reviewId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/complete-review?reviewId=' + reviewId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更新评审参与人 */
export async function updateReviewer(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/update-reviewer', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 更新会议参与人 */
export async function updateMeetingReviewer(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/update-meeting-reviewer', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}






/** 删除规划 */
export async function delPlan(planId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-plan/delete?planId=' + planId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除需求 */
export async function delIssue(issueId: string, delReason: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/delete-issue', {
    method: 'GET',
    params: {
      issueId: issueId,
      deleteReason: delReason
    },
    ...(options || {}),
  });
}
/** 恢复需求 */
export async function restoreIssue(issueId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/restore-issue?issueId=' + issueId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 复制需求 */
export async function cloneIssue(issueId: number, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/clone?issueId=' + issueId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 需求转移 */
export async function transferIssue(
  issueId: string,
  systemId: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>(
    '/issue-v2/transfer?issueId=' + issueId + '&systemId=' + systemId,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 获取评审列表 */
export async function pagedReviews(
  params: {
    current?: number;
    pageSize?: number;
    systemId?: string;
    relationId?:string;
  },
  options?: { [key: string]: any },
) {
  return request<API.ReviewList>('/issue-review/page-review', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取系统 */
export async function listPlans(
  systemId: string,
  planType: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>(
    '/issue-plan/list-plan?systemId=' + systemId + '&planType=' + planType,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}
/**获取工作项是否要预约 */
export async function isNeedAppoint(itemType: string, itemId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/is-need-appoint', {
    method: 'GET',
    params: {
      itemType,
      itemId
    },
    ...(options || {}),
  });
}


// /** 获取评审 */
// export async function getReviewsByIssueId(issueId: string) {
//   return request<API.ImileResponse<any>>(
//     '/issue-v2/issue-review-detail?issueId=' + issueId,
//     {
//       method: 'GET',
//     },
//   );
// }

/** 获取关联项评审明细 */
export async function getRelationReviewDetail(relationId, reviewType) {
  return request<API.ImileResponse<any>>(
    '/issue-review/relation-review-detail?relationId=' + relationId + '&reviewType=' + reviewType,
    {
      method: 'GET',
    },
  );
}

/** 获取关联需求 */
export async function getRelatedIssueByIssueId(issueId: string) {
  return request<API.ImileResponse<any>>('/issue-v2/related-issue-detail?issueId=' + issueId, {
    method: 'GET',
  });
}

/** 获取测试用例 */
export async function getTestCaseByIssueId(issueId: string) {
  return request<API.ImileResponse<any>>('/issue-v2/issue-test-case-detail?issueId=' + issueId, {
    method: 'GET',
  });
}

/** 获取测试缺陷 */
export async function getBugByIssueId(issueId: string, env: string) {
  return request<API.ImileResponse<any>>(
    '/bug-record/list-bugs-by-issueId?issueId=' + issueId + '&env=' + env,
    {
      method: 'GET',
    },
  );
}
/** 获取迭代测试缺陷 */
export async function getBugByPlanId(planId: string) {
  return request<API.ImileResponse<any>>(
    '/issue-plan/list-bugs-by-planId?planId=' + planId ,
    {
      method: 'GET',
    },
  );
}
/** 获取需求 */
export async function pagedIssues(
  params: {
    current?: number;
    pageSize?: number;
    systemId?: string;
    progressList?: string[];
    startDate?: any;
    endDate?: any;
  },
  options?: { [key: string]: any },
) {
  return request<API.IssueList>('/issue-v2/page-issue', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params,
    },
    ...(options || {}),
  });
}

/** 添加关联需求 */
export async function addRelatedIssue(body: {}, options?: { [key: string]: any }) {
  return request<API.SystemList>('/issue-v2/add-related-issue', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 删除关联需求 */
export async function delRelatedIssue(body: {}, options?: { [key: string]: any }) {
  return request<API.SystemList>('/issue-v2/delete-related-issue', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 通过需求序列号查需求记录 */
export async function getByIssueSn(issueSn: string) {
  return request<API.ImileResponse<any>>('/issue-v2/get-by-issueSn?issueSn=' + issueSn, {
    method: 'GET',
  });
}

/** 通过需求Id查需求记录 */
export async function getMaxRangeDateByissueIds(issueIds: any[]) {
  return request<API.ImileResponse<any>>('/issue-v2/get-maxRangeDate-by-issueIds', {
    method: 'POST',
    data: issueIds,
  });
}


/** 获取会议室列表 */
export async function getMeetingRooms(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-review/rooms', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 获取会议室位置联动信息 */
export async function getMeetingRoomLinkageInfo() {
  return request<API.ImileResponse<any>>('/issue-review/rooms-linkage', {
    method: 'GET',
  });
}


/** 发送催确认消息 */
export async function sendReviewMeetingMag(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/issue-review/send-meeting-msg', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 获取git提交记录 */
export async function getCommits(
  body: {},
  options?: { [key: string]: any }
) {
  return request<API.ImileResponse<any>>('/git-commit/list', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}

/** 获取需求 */
export async function pagedIssueTree(
  params: {
    current?: number;
    pageSize?: number;
    systemId?: string;
    progressList?: string[];
    startDate?: any;
    endDate?: any;
  },
  options?: { [key: string]: any },
) {
  return request<API.IssueList>('/issue-v2/page-issue-tree', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params,
    },
    ...(options || {}),
  });
}


/** 一键创建需求任务 */
export async function createIssueTask(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/create-issue-task', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}


export async function searchIssueCnt(searchName: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/search-issues-cnt', {
    method: 'GET',
    params: {
      searchName: searchName
    },
    ...(options || {}),
  });
}

// 获取需求状态分阶段流转明细
export async function getIssueStatusFlowDetail(issueId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-v2/get-status-flow-detail', {
    method: 'GET',
    params: {
      issueId: issueId
    },
    ...(options || {}),
  });
}

/** 查询所有值班表 */
export async function pagedDuty(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/paged-duty', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 查询值班表头 */
export async function getDutyHeader(dutyId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/list-duty-column-by-dutyId', {
    method: 'GET',
    params: {
      dutyId: dutyId
    },
    ...(options || {}),
  });
}
/** 查询值班列表 */
export async function getDutyList(dutyId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/list-duty-data-by-dutyId', {
    method: 'GET',
    params: {
      dutyId: dutyId
    },
    ...(options || {}),
  });
}
/** 查询值班详情 */
export async function getDutyDetailList(dutyId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/get-duty-detail-by-dutyId', {
    method: 'GET',
    params: {
      dutyId: dutyId
    },
    ...(options || {}),
  });
}
/** 新增值班表 */
export async function createDutyList(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/create-duty', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 修改值班表 */
export async function editDutyList(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/update-duty', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 删除值班表 */
export async function deleteDutyList(dutyId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/del-duty', {
    method: 'GET',
    params: {
      dutyId: dutyId
    },
    ...(options || {}),
  });
}
/** 修改值班人员 */
export async function editDutyUser(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/change-duty-user', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 修改值班负责人 */
export async function editDutyLeader(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/duty-record/change-duty-column-user', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 查询节假日 */
export async function getHolidays(year: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/calendar-record/list-calendar-by-year', {
    method: 'GET',
    params: {
      year: year
    },
    ...(options || {}),
  });
}
/** 需求下代码质量 */
export async function getIssueQuality(issueId: number,type?:string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/code-quality/list-issue-quality', {
    method: 'GET',
    params: {
      issueId: issueId,
      type:type
    },
    ...(options || {}),
  });
}
/** 迭代下代码质量 */
export async function getPlanQuality(planId: number, type?:string,options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/code-quality/list-plan-quality', {
    method: 'GET',
    params: {
      planId: planId,
      type:type
    },
    ...(options || {}),
  });
}

/**获取预约记录月视图 */
export async function getMonthView(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/month-view', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/**获取所有预约 */
export async function getAllAppointments(body?: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/page', {
    method: 'POST',
    data: {
      currentPage: body?.current,
      showCount: body?.pageSize,
      ...body,
    },
    ...(options || {}),
  });
}
/** 创建预约 */
export async function createAppointment(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/create', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/** 修改预约 */
export async function editAppointment(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/update', {
    method: 'POST',
    data: {
      ...body,
    },
    ...(options || {}),
  });
}
/**分页查预约记录 */
export async function pageAppointment(
  body?: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/release-appointment/page', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/**获取预约详情  */
export async function getAppointmentDetail(params:any,options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/detail', {
    method: 'GET',
    params,
    ...(options || {}),
  });
}
/**更新预约状态 */
export async function changeStatus(  body: any,  options?: { [key: string]: any },) {
  return request<API.ImileResponse<any>>('/release-appointment/change-status', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/**获取截止工作项详情  */
export async function getItemEndDetail(params:any,options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/item-end-detail', {
    method: 'GET',
    params,
    ...(options || {}),
  });
}
/**获取预约工作项能拉出来的服务分支信息 */
export async function serviceBranch(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/release-appointment/service-branch', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/** 删除预约 */
export async function delAppointment(id: number,options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release-appointment/delete', {
    method: 'GET',
    params: {
      id: id,
    },
    ...(options || {}),
  });
}

/**获取评审会议纪要信息 */
export async function getRreviewMeetingMinutes(reviewId:string) {
  return request<API.ImileResponse<any>>('/issue-review/get-review-meeting-minutes', {
    method: 'GET',
    params: {
      reviewId: reviewId,
    },
  });
}

/**获取提测记录明细 */
export async function getLastDetailByIssue(issueId:string) {
  return request<API.ImileResponse<any>>('/submit-test/last-detail-by-issue', {
    method: 'GET',
    params: {
      issueId: issueId,
    },
  });
}

/**判断需求是否提测校验通过 */
export async function getSubmitTestCheckResult(issueId:string) {
  return request<API.ImileResponse<any>>('/submit-test/check-result', {
    method: 'GET',
    params: {
      issueId: issueId,
    },
  });
}

/**创建提测记录 */
export async function createSubmitTest(body: any,  options?: { [key: string]: any },) {
  return request<API.ImileResponse<any>>('/submit-test/create', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/**获取需求的提测记录 */
export async function getIssueOfSubmitTest(issueId:string) {
  return request<API.ImileResponse<any>>('/submit-test/list-by-issue', {
    method: 'GET',
    params: {
      issueId: issueId,
    },
  });
}

/**获取迭代的提测记录 */
export async function getSprintOfSubmitTest(sprintId:string) {
  return request<API.ImileResponse<any>>('/submit-test/list-by-sprint', {
    method: 'GET',
    params: {
      sprintId: sprintId,
    },
  });
}



/**根据提测id获取提测记录 */
export async function getSubmitTestDetailById(id:string) {
  return request<API.ImileResponse<any>>('/submit-test/detail-by-id', {
    method: 'GET',
    params: {
      id: id,
    },
  });
}

