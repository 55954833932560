// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';



/** 分页查周报 */
export async function pagedIndustry(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/industry-line-record/paged', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params,
    },
    ...(options || {}),
  });
}

/** 下拉列表*/
export async function selectIndustryList(param: any) {
  return request('/industry-line-record/select-list', {
    data: param,
    method: 'POST',
  });
}



/** 查询详情 */
export async function getInsustryDetail(id: string) {
  return request('/industry-line-record/detail', {
    params: { id },
    method: 'GET',
  });
}

/** 创建 */
export async function createInsustry(param: any) {
  return request('/industry-line-record/create', {
    data: param,
    method: 'POST',
  });
}


/** 编辑*/
export async function updInsustry(param: any) {
  return request('/industry-line-record/update', {
    data: param,
    method: 'POST',
  });
}


/** 删除 */
export async function delInsustry(id: string) {
  return request('/industry-line-record/delete', {
    params: { id },
    method: 'GET',
  });
}




