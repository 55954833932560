import { useState, useRef, useEffect } from 'react';
import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import initialState from "@@/plugin-initialState/@@initialState";
import { useModel } from "@@/exports";


const useWebsocket = () => {
  const ws = useRef<WebSocket | null>(null);
  // socket 数据
  let [wsData, setMessage] = useState("");
  //  socket 状态
  const [readyState, setReadyState] = useState<any>({ key: 0, value: '正在连接中' });

  const [timeout] = useState(30000);
  let [timeoutObj] = useState<Timeout>();
  const [serverTimeoutObj] = useState<Timeout>();
  const { initialState, setInitialState } = useModel('@@initialState');


  const creatWebSocket = () => {
    const stateArr = [
      { key: 0, value: '正在连接中' },
      { key: 1, value: '已经连接并且可以通讯' },
      { key: 2, value: '连接正在关闭' },
      { key: 3, value: '连接已关闭或者没有连接成功' },
    ];


    try {
      ws.current = new WebSocket(`${WS_URL}` + initialState?.currentUser?.userName);

      ws.current.onopen = () => {
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        startHeartBeat();
      };
      ws.current.onclose = () => {
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
      };
      ws.current.onerror = () => {
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
      };
      ws.current.onmessage = e => {
        console.log(e)
        setMessage(e.data)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        resetHeartBeat();
        //   const { data, type } = (...JSON.parse(e.data)) || {};
        //   switch (
        //     type // type 是跟后端约定的
        //     ) {
        //     case '101':
        //       setMessage({ ...JSON.parse(e.data) },review: data); //根据自身情况进行修改
        //       break;
        //     case '102':
        //       setMessage({ ...JSON.parse(e.data) },pipelineResults: data);//根据自身情况进行修改
        //       break;
        //     default:
        //       setMessage({ ...JSON.parse(e.data), ...data });//根据自身情况进行修改
        //       break;
        //   }
      };
    } catch (error) {
      console.log(error);
    }
  };

  const webSocketInit = () => {
    if (!ws.current || ws.current.readyState === 3) {
      creatWebSocket();
    }
  };

  //  关闭 WebSocket
  const closeWebSocket = () => {
    ws.current?.close();
  };


  // 发送数据
  const sendMessage = (str: string) => {
    console.log(str, '------------str');
    ws.current?.send(str);
  };

  //重连
  const reconnect = () => {
    try {
      closeWebSocket();
      ws.current = null;
      creatWebSocket();
    } catch (e) {
      console.log(e);
    }
  };
  //开始心跳
  const startHeartBeat = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    timeoutObj && clearTimeout(timeoutObj)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    serverTimeoutObj && clearTimeout(serverTimeoutObj)
    timeoutObj = setTimeout(function () {
      // 判断websocket当前状态
      // @ts-ignore
      if (ws.current.readyState === 1) {
        ws.current?.send('HeartBeat')
      }
    }, timeout);
  };

  //重制心跳
  const resetHeartBeat = () => {
    clearTimeout(timeoutObj);
    startHeartBeat();
  };


  useEffect(() => {
    webSocketInit();
    return () => {
      ws.current?.close();
    };
  }, [ws]);

  //    wsData （获得的 socket 数据）、readyState（当前 socket 状态）、closeWebSocket （关闭 socket）、reconnect（重连）
  return {
    wsData,
    readyState,
    closeWebSocket,
    reconnect,
    sendMessage,
  };
};
export default useWebsocket;
