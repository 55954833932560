import { Input, Popover, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useModel, useRequest } from "@@/exports";
import { setUserSystemCache } from "@/services/common/api";
import { listSelectIssue, searchIssueCnt } from "@/services/rd/issues/api";
import { history } from 'umi';




const APP: React.FC = () => {
  const [type, setType] = useState('product');

  const [list, setList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [searchName, setSearcName] = useState('');
  const { initialState } = useModel('@@initialState');
  const [initialPath, setInitialPath] = useState(null); // 保存初始路由
  const location = useLocation();


  const { data: selectIssueList, run: runSelectIssueList } = useRequest((param) => {
    return listSelectIssue(param)
  }, { manual: true })

  const { data: search, run: runSearch } = useRequest((param) => {
    return searchIssueCnt(param)
  }, {
    manual: true
  })


  useEffect(() => {
    runSelectIssueList({})
  }, [])

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.indexOf('/rd/businessIssue') !== -1) {
      setType('business')
    } else if (pathname.indexOf('/rd/sprint') !== -1) {
      setType('sprint')
    } else if (pathname.indexOf('/rd/review') !== -1) {
      setType('review')
    } else if (pathname.indexOf('/rd/test/bug') !== -1) {
      setType('testBug')
    } else if (pathname.indexOf('/rd/tasks') !== -1) {
      setType('task')
    } else if (pathname.indexOf('/rd/onlineBug') !== -1) {
      setType('prodBug')
    } else {
      setType('product')
    }

  }, [location.pathname])


  // const changeSystem = async (val) => {
  //   const { resultCode } = await setUserSystemCache(val);
  //   console.log(val)
  //   if (resultCode === 'success') {
  //     setDefaultSystem(val)
  //     window.location.reload();
  //   }

  // }

  const selectAfter = (
    <Select
      defaultValue="product"
      value={type}
      popupMatchSelectWidth={90}
      onChange={(e) => { setType(e) }}
      options={[{ value: 'business', label: '业务需求' },
      { value: 'product', label: '产品需求' },
      { value: 'sprint', label: 'Sprint' },
      { value: 'review', label: '评审' },
      { value: 'task', label: '任务中心' },
      { value: 'testBug', label: '测试缺陷' },
      { value: 'prodBug', label: '线上问题' },]}
    />
  );

  const jumpUrl = (e: any) => {
    let url = '/rd/productIssue'
    const queryParams = { name: searchName, radioType: e.target.value };
    switch (type) {
      case 'business':
        url = '/rd/businessIssue'
        break;
      case 'product':
        break;
      case 'sprint':
        url = '/rd/sprint/table'
        break;
      case 'review':
        url = '/rd/review'
        break;
      case 'task':
        url = '/rd/tasks'
        break;
      case 'testBug':
        url = '/rd/test/bug'
        break;
      case 'prodBug':
        url = '/rd/onlineBug'
        break;
    }
    history.push({
      pathname: url,
      search: new URLSearchParams(queryParams).toString()
    })
    // history.push(url, { name: value })
  }


  const content = (
    <Radio.Group onChange={jumpUrl}>
      <Radio.Button value='running'>进行中{search?.runningCnt || 0}</Radio.Button>
      <Radio.Button value='draft'>草稿箱{search?.draftCnt || 0}</Radio.Button>
      <Radio.Button value='closed'>已归档{search?.closedCnt || 0}</Radio.Button>
      <Radio.Button value='recycle'>回收站{search?.recycleCnt || 0}</Radio.Button>
    </Radio.Group>
  );

  useEffect(() => {
    // console.log(location)
    if (!location.search) {
      // console.log(location.search)
      setInitialPath(location.pathname);
    }
  }, [location.pathname]);


  return (
    <>
      {/* <Popover content={content}
        // title="Title"
        trigger="click"
        open={open}
        arrow={false}
        style={{ marginBottom: 24 }}
        onOpenChange={(e) => { setOpen(e) }}> */}
      <Input.Search placeholder="全局搜索"
        allowClear={true}
        addonBefore={selectAfter}
        onSearch={(value) => {
          if (!value) {
            // setOpen(false)
            // console.log(initialPath)
            // history.back()
            history.replace(initialPath || '/'); // 跳转到初始路由
            return
          }
          setSearcName(value)
          const queryParams = { name: value };
          let url = '/rd/productIssue'
          switch (type) {
            case 'business':
              url = '/rd/businessIssue'
              break;
            case 'product':
              // runSearch(value)
              // setOpen(true)
              break;
            case 'sprint':
              url = '/rd/sprint/table'
              break;
            case 'review':
              url = '/rd/review'
              break;
            case 'task':
              url = '/rd/tasks'
              break;
            case 'testBug':
              url = '/rd/test/bug'
              break;
            case 'prodBug':
              url = '/rd/onlineBug'
              break;
          }
          history.push({
            pathname: url,
            search: new URLSearchParams(queryParams).toString()
          })
          // history.push(url, { name: value })
        }} style={{ width: 300 }} />
      {/* </Popover> */}

    </>
  );
};

export default APP;
