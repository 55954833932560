export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名和密码(admin/ant.design)',
  'pages.login.failure': '登录失败，请重试！',
  'pages.login.success': '登录成功！',
  'pages.login.username.placeholder': '用户名: admin or user',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码: ant.design',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',

  'pages.common.createDate': '创建时间',
  'pages.common.id': '序号',
  'pages.common.createUserName': '创建人',
  'pages.common.lastUpdDate': '更新时间',
  'pages.common.lastUpdUserName': '更新人',
  'pages.common.option': '操作',
  'pages.common.create': '新建',
  'pages.common.edit': '编辑',
  'pages.common.delete': '删除',
  'pages.common.restore': '恢复',
  'pages.common.copy': '克隆',
  'pages.common.status': '状态',
  'pages.common.share': '分享',
  'pages.common.tag': '标签',
  'pages.common.productManagerName': '产品经理',
  'pages.common.uiDesignName': 'UI设计',
  'pages.common.developName': '后端开发',
  'pages.common.frontWebName': '前端开发',
  'pages.common.testEngineerName': '测试开发',
  'pages.common.testName': '测试',
  'pages.common.itSupportName': '技术支持',
  'pages.common.businessCounterpartName': '业务方',
  'pages.common.form.selectProduct': '请选择产品经理',
  'pages.common.form.selectBusinessCounterpartName': '请选择业务方',
  'pages.common.form.selectUdDesign': '请选择UI设计',
  'pages.common.form.selectDev': '请选择后端开发',
  'pages.common.form.selectFont': '请选择前端开发',
  'pages.common.form.selectTest': '请选择测试开发',
  'pages.common.form.selectSupport': '请选择技术支持',
  'pages.common.startTime': '开始时间',
  'pages.common.endTime': '结束时间',
  'pages.common.colSetting': '列设置',
  'pages.common.closed': '归档',
  'pages.common.filter': '筛选',
  'pages.common.clear': '清除',
  'pages.common.notarize': '确认',
  'pages.common.addRemark': '添加备注',
  'pages.common.assign': '指派处理人',

  'pages.business.businessNameCn': '业务领域(中)',
  'pages.business.businessNameEn': '业务领域(英)',
  'pages.business.systemId': '关联系统',
  'pages.business.title': '业务领域',

  'pages.system.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.system.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.system.status': '启用状态',
  'pages.system.title': '系统',
  'pages.system.name': '系统名称',
  'pages.system.systemId': '系统ID（关联Jira的SystemId）',
  'pages.system.systemKey': '系统Key',
  'pages.system.ruleName': '系统名称为必填项',
  'pages.system.businessName': '业务领域',
  'pages.system.businessNameEn': '业务领域-英',
  'pages.system.leaderUserName': '系统负责人',
  'pages.system.businessLeaderUserName': '业务领域负责人',
  'pages.system.businessUserCount': '业务参与人数',
  'pages.system.systemDesc': '系统描述',
  'pages.system.exception': '请输入异常原因！',
  'pages.system.form.businessName': '所属业务领域',
  'pages.system.form.systemKey': '系统Key（唯一）',
  'pages.system.form.selectBusiness': '请选择业务领域',
  'pages.system.form.selectSystemLeader': '请选择系统负责人',
  'pages.system.form.inputSystemName': '请输入系统名称',
  'pages.system.form.inputSystemId': '请输入关联Jira系统ID',
  'pages.system.form.inputSystemKey': '请输入系统Key',
  'pages.system.form.robotId': '机器人ID',

  'pages.businessIssue.productManagerName': '产品对接人',
  'pages.businessIssue.productManagerName2': '对接人',
  'pages.businessIssue.businessSideInfo': '提出人',
  'pages.businessIssue.pengingTime': '等待时长(天)',
  'pages.businessIssue.pengingTime2': '等待时长',
  'pages.businessIssue.expectDate': '期待完成时间',
  'pages.businessIssue.relatedIssues': '关联产品需求',
  'pages.businessIssue.relatedIssuesCount': '关联产品需求数量',
  'pages.businessIssue.createProductIssue': '创建产品需求',

  'pages.issuePool.issueId': '序列号',
  'pages.issuePool.issueName': '需求名称',
  'pages.issuePool.system': '所属系统',
  'pages.issuePool.relatedUser': '相关人(抄送人)',
  'pages.issuePool.complexRate': '复杂度',
  'pages.issuePool.country': '国家',
  'pages.issuePool.label': '标签',
  'pages.issuePool.priorityLevel': '优先级',
  'pages.issuePool.sprint': '所属Sprint',
  'pages.issuePool.expectDate': '期待完成时间',
  'pages.issuePool.completeDate': '实际完成时间',
  'pages.issuePool.expectDate2': '期待完成',
  'pages.issuePool.completeDate2': '实际完成',
  'pages.issuePool.reviewDate': '评审时间',
  'pages.issuePool.allReviewDate': '时间',

  'pages.issuePool.relatedIssues': '关联需求',
  'pages.issuePool.follower': '关注',
  'pages.issuePool.cancelFollower': '取消关注',
  'pages.issuePool.transfer': '转移系统',
  'pages.issuePool.planName': '规划名称',
  'pages.issuePool.allIssues': '全部需求',
  'pages.issuePool.currentSprint': '查看当前Sprint',
  'pages.issuePool.taskCnt': '任务数量',
  'pages.issuePool.release': '创建发布单',
  'pages.issuePool.isTechnical': '技术类需求',
  'pages.issuePool.isRequireTrainer': '需要系统培训',
  'pages.issuePool.addPlan': '需求绑定Sprint(默认展示需求所属系统的Sprint)',
  'pages.issuePool.follewUsers': '关注者',
  'pages.issuePool.taskTotalTime': '任务总耗时',
  'pages.issuePool.taskTime': '任务耗时',

  'pages.issuePool.form.selectIssueName': '请输入标题',
  'pages.issuePool.form.selectExpectDate': '请选择期待日期',
  'pages.issuePool.form.selectComplexRate': '请选择复杂度',
  'pages.issuePool.form.selectPriorityLevel': '请选择优先级',
  'pages.issuePool.form.estimatedPersonDay': '预估人天',
  'pages.issuePool.form.businessReviewName': '业务评审名称',
  'pages.issuePool.form.collaborativeIssue': '协同需求',
  'pages.issuePool.form.relatedSprint': '关联Sprint',
  'pages.issuePool.form.country': '需求影响国家',
  'pages.issuePool.form.isRequireTrainer': '是否需要系统培训',

  'pages.release.collaboratorList': '协同人',
  'pages.release.Date': '发布日期',

  'pages.issueReview.name': '评审名称',
  'pages.issueReview.allName': '名称',
  'pages.issueReview.createUserName': '发起人',
  'pages.issueReview.reviewUsers': '评审参与人',
  'pages.issueReview.status': '评审状态',
  'pages.issueReview.reviewType': '评审类型',
  'pages.issueReview.reviewReadCount': '确认情况',
  'pages.issueReview.reviewTime': '预估评审时间',
  'pages.issueReview.relatedIssueCount': '关联需求数',
  'pages.issueReview.meetingCount': '会议数',
  'pages.issueReview.meetingCode': '点击复制会议号',
  'pages.issueReview.createMeeting': '发起会议',
  'pages.issueReview.editMeeting': '编辑会议',
  'pages.issueReview.cancelMeeting': '取消会议',
  'pages.issueReview.cancelReview': '删除评审',
  'pages.issueReview.ReviewAgain': '重新评审',
  'pages.issueReview.reviewDesc': '评审描述',
  'pages.issueReview.form.meetingName': '评审会议名称',
  'pages.issueReview.form.allMeetingName': '会议名称',
  'pages.issueReview.form.meetingTime': '会议时长',
  'pages.issueReview.form.reviewMeetingDesc': '评审会议描述',
  'pages.issueReview.form.allReviewMeetingDesc': '会议描述',
  'pages.issueReview.form.plan': '规划',
  'pages.issueReview.form.issues': '需求',
  'pages.issueReview.form.reviewMeetingContent': '评审会议纪要',
  'pages.issueReview.issuePool.unReadCount': '未读人数',
  'pages.issueReview.issuePool.unReadUser': '未读人',
  'pages.issueReview.issuePool.readCount': '已读人数',
  'pages.issueReview.issuePool.readUser': '已读人',
  'pages.issueReview.meetingRemindBefores': '开始前提醒',

  'pages.sprint.name': 'Sprint名称',
  'pages.sprint.owner': '负责人',
  'pages.sprint.goal': 'Sprint(迭代)目标',
  'pages.sprint.issueCnt': '需求数量',
  'pages.sprint.cycle': '周期(GMT+8)',
  'pages.sprint.budget': '人天预算(Day)',
  'pages.sprint.isSynergy': '协同规划',
  'pages.sprint.plan': 'Sprint规划',
  'pages.sprint.version': '版本号',
  'pages.sprint.createVersion': '创建迭代',
  'pages.sprint.form.time': '开始-结束',
  'pages.sprint.form.issues': '需求',
  'pages.sprint.add.issue': '添加需求',
  'pages.sprint.systemNames': '所属系统',

  'pages.task.id': '任务ID',
  'pages.task.name': '任务名称',
  'pages.task.type': '类型',
  'pages.task.complexRate': '复杂度',
  'pages.task.priorityLevel': '优先级',
  'pages.task.status': '状态',
  'pages.task.owner': '负责人',
  'pages.task.taskParticipants': '参与人',
  'pages.task.task': '任务详情列表',
  'pages.task.estimatedPersonDay': '预估人天',
  'pages.task.issues': '需求',
  'pages.task.serviceType': '服务类型',
  'pages.task.service': '服务',
  'pages.task.branch': '分支',
  'pages.task.overtime': '已逾期',
  'pages.task.form.time': '开始-结束',
  'pages.task.sprint': '迭代',

  'pages.test.caseName': '用例名称',
  'pages.test.systemName': '所属系统',
  'pages.test.issueName': '所属需求',
  'pages.test.moduleName': '所属模块',
  'pages.test.caseLevel': '用例级别',
  'pages.test.reviewStatusDesc': '评审状态',
  'pages.test.fileName': '文件名称',

  'pages.bug.title': '标题',
  'pages.bug.system': '所属系统',
  'pages.bug.issue': '所属需求',
  'pages.bug.severityLevel': '严重级别',
  'pages.bug.priorityLevel': '优先级',
  'pages.bug.implementationPlan': '执行方案',
  'pages.bug.bugType': 'Bug类型',
  'pages.bug.proposer': '反馈人',
  'pages.bug.assignedUser': '指派给',
  'pages.bug.assignedDate': '指派时间',
  'pages.bug.deadline': '截止时间',
  'pages.bug.relatedIssuesCount': '关联产品需求数量',
  'pages.bug.bugPageUrlsCnt': 'Bug页面URL数	',
  'pages.bug.responseTime': '响应耗时',
  'pages.bug.processingTime': '处理耗时',
  'pages.bug.waitingCloseTime': '等待关闭',
  'pages.bug.waitingTime': '总耗时',
  'pages.bug.carbonCopyUser': '抄送人',
  'pages.bug.operatingSystem': '操作系统',
  'pages.bug.resolvedUser': '解决者',
  'pages.bug.confirmedDate': '确认时间',
  'pages.bug.resolvedDate': '解决时间',
  'pages.bug.closedUser': '关闭者',
  'pages.bug.closedDate': '关闭时间',
  'pages.bug.confirm': '确认',
  'pages.bug.resolve': '解决',
  'pages.bug.reopen': '重开',
  'pages.bug.closed': '关闭',
  'pages.bug.reminder': '催办',
  'pages.bug.clone': '克隆',

  'pages.codeQuality.title':'项目/服务',
  'pages.codeQuality.type':'类别',
  'pages.codeQuality.lines':'代码总行数',
  'pages.codeQuality.changeLines':'代码行数变化',
  'pages.codeQuality.addLines':'增量代码行数',
  'pages.codeQuality.allLines':'代码总行数',
  'pages.codeQuality.bugs':'缺陷数量',
  'pages.codeQuality.bugDensity':'千行代码缺陷率（‰）',
  'pages.codeQuality.addBugDensity':'增量代码缺陷率（％）',
  'pages.codeQuality.allBugDensity':'全量千行代码缺陷率（‰）',

  'pages.efficiencyDashboard.title': '需求交付概览',
  'pages.efficiencyDashboard.issueStatistics': '需求存量统计',
  'pages.efficiencyDashboard.stockCount': '总存量',
  'pages.efficiencyDashboard.pendingInterventionsCount': '待介入',
  'pages.efficiencyDashboard.suspendedCount': '已暂停',
  'pages.efficiencyDashboard.underSolutionCount': '方案中',
  'pages.efficiencyDashboard.toBeDevelopedCount': '待开发',
  'pages.efficiencyDashboard.underDevelopmentCount': '开发中',
  'pages.efficiencyDashboard.recentSevenDayDasboard': '近7天需求概览',
  'pages.efficiencyDashboard.newCount': '新增',
  'pages.efficiencyDashboard.closeCount': '关闭',
  'pages.efficiencyDashboard.totalReleaseCount': '总发布',
  'pages.efficiencyDashboard.normalReleaseCount': '正常发布',
  'pages.efficiencyDashboard.emergencyReleaseCount': '紧急发布',
  'pages.efficiencyDashboard.delayedUnpublishedCount': '延迟未发布',
  'pages.efficiencyDashboard.recentSevenDayQuality': '近7天需求质效',
  'pages.efficiencyDashboard.handledWaitTime': '需求等待时长（已处理） 单位: 小时',
  'pages.efficiencyDashboard.designTime': '需求设计时长（已处理） 单位: 小时',
  'pages.efficiencyDashboard.recentSevenDayOption': '近7天操作',
  'pages.efficiencyDashboard.changeCount': '状态变更次数',

  // polarDB日志
  'pages.polarDB.log.id': 'ID',
  'pages.polarDB.log.environment': '环境',
  'pages.polarDB.log.dbClusterId': '集群ID',
  'pages.polarDB.log.dbName': '数据库',
  'pages.polarDB.log.dbNodeId': '节点ID',
  'pages.polarDB.log.executionStartTime': 'SQL执行时间（UTC）',
  'pages.polarDB.log.hostAddress': '客户端地址',
  'pages.polarDB.log.lockTimes': 'SQL锁定时长（秒）',
  'pages.polarDB.log.queryTimes': 'SQL执行时长（秒）',
  'pages.polarDB.log.parseRowCounts': '解析行数',
  'pages.polarDB.log.returnRowCounts': '返回行数',
  'pages.polarDB.log.sqlText': '查询语句',
  'pages.polarDB.log.dataTimestamp': 'SQL执行时间戳',
  'pages.polarDB.log.sqlTemplateId': 'SQL模板id',

  // polarDB模板
  'pages.polarDB.tempalte.id': '模板id',
  'pages.polarDB.tempalte.environment': '环境',
  'pages.polarDB.tempalte.dbClusterId': '集群ID',
  'pages.polarDB.tempalte.dbName': '数据库',
  'pages.polarDB.tempalte.dbNodeId': '节点ID',
  'pages.polarDB.tempalte.hostAddress': '客户端地址',
  'pages.polarDB.tempalte.sqlText': 'SQL模板',
  'pages.polarDB.tempalte.createDate': '拉取时间',
  'pages.polarDB.tempalte.lastUpdDate': '更新时间',
  'pages.polarDB.tempalte.lastUpdUserName': '更新人',
  'pages.polarDB.tempalte.overdueDays': '逾期天数',
  'pages.polarDB.tempalte.handlerInfo': '处理人',

  'pages.polarDB.tempalte.status': '是否处理',
  'pages.polarDB.tempalte.remark': '备注',

  // 数据库订阅
  'pages.db.subscribe.dbId': '数据库id',
  'pages.db.subscribe.dbName': '数据库',
  'pages.db.subscribe.dbLeader': '负责人',
  'is.subscribe': '是否订阅',
  'subscribers': '订阅人',
  'pages.db.subscribe.explain':
    '须知：订阅数据库后，第二天开始会通过应用推送订阅数据库昨日的慢sql模板数据！（无慢SQL,则不推送）',
  'edit.subscribers': '编辑订阅人',

  // 标签
  'pages.label.status': '启用状态',
  'pages.label.type': '标签类型',
  'pages.label.nameCn': '标签名称(中文)',
  'pages.label.nameEn': '标签名称(英文)',
  'pages.label.markingCnt': '打标数量',
  'pages.label.lastUpdDate': '更新时间',
  'pages.label.lastUpdUserName': '更新人',
  'pages.label.relationTypeName': '打标类型',
  'pages.label.relationName': '打标名称',
  'pages.label.createDate': '打标时间',
  'pages.label.createUserName': '打标人',

  // 交付
  'pages.delivery.id': 'ID',
  'pages.delivery.releaseCarrierId': '发布单ID',
  'pages.delivery.releaseCarrierName': '发布单名称',
  'pages.delivery.releaseCarrierType': '发布单类型',
  'pages.delivery.collaboratorInfos': '协同人',
  'pages.delivery.otherInfo': '其它信息',
  'pages.delivery.isEmergency': '紧急发布',
  'pages.delivery.progress': '状态',
  'pages.delivery.progressDesc': '状态',
  'pages.delivery.appointmentDate': '预计发布时间',
  'pages.delivery.releaseDate': '实际发布时间',
  'pages.delivery.checkTermList': '检查项',
  'pages.delivery.issueSn': '需求序列号',
  'pages.delivery.taskCnt': '任务数量',
  'pages.delivery.issueUrl': '需求详情',

  'pages.delivery.serviceName': '服务名',
  'pages.delivery.serviceType': '服务类型',
  'pages.delivery.serviceStatus': '流水线状态',
  'pages.delivery.devOwnerNames': '开发Owner',
  'pages.delivery.developerNames': '开发者',
  'pages.delivery.businessName': '业务领域',
  'pages.delivery.serviceLevel': '服务级别',
  'pages.delivery.serviceNature': '服务属性',
  'pages.delivery.replicas': '容器副本',
  'pages.delivery.branch': '当前分支',
  'pages.delivery.releaseNum': '发布次数',
  'pages.delivery.emergencyReleaseNum': '紧急次数',
  'pages.delivery.updateBy': '最新操作人',
  'pages.delivery.updateTime': '最新操作时间',

  'start.up.today': '今日生产投产',
  'delivery.today': '今日交付',
  'publish.window': '发布窗口期',
  'service.publishing': '服务发布',
  'release.reason': '发布原因',
  'code.branch': '代码分支',
  'oneClick.publishing': '一键发布',
  'secondary.release': '二次发布',

  'noLpt.publishing': '非生产环境发布',
  'regression.completion': '回归完成',
  'acceptance.pass': '验收通过',
  'release.note': '发布说明',
  'requirement.acceptance.link': '需求验收链接',

  'release.history': '发布历史',
  'release.log': '发布日志',
  'service.log': '服务日志',

  'vulnerability.scanning': '漏洞扫描',
  publish: '发布',
  rollback: '回滚',

  'run.id': '运行ID',
  branch: '分支',
  'commit.id': '提交ID',
  'run.time': '运行时间',
  'opt.time': '操作时间',
  'end.time': '结束时间',
  'op.user': '操作人',
  'finish.desc': '部署描述',
  'please.select.a.branch': '请选择分支',
  'commit.publish': '提交发布',
  'commit.scanner': '提交扫描',
  'error.log': '错误日志',
  'release.env': '发布环境',
  'product.requirements': '产品需求',
  'pull.carrier.branch': '拉取发布单分支',
  'refresh.branch': '刷新分支',
  'related.items': '关联项',
  'whether.to.schedule.a.meeting': '是否预约会议',
  'review.issue': '评审需求',
  'review.sprint': '评审Sprint',
  'review.test.case': '评审测试用例',
  'meeting.code': '会议号',
  'edit.review': '编辑评审',
  'edit.allReview': '编辑',
  'complete.review': '完成评审',
  'initiate.a.review.meeting': '预约评审会议',
  'initiate.a.allReview.meeting': '预约会议',
  'update.review.meeting': '变更评审会议',
  'update.review.allMeeting': '变更会议',
  'fill.in.the.review.minutes': '填写会议纪要',
  'cancel.review.meeting': '取消评审会议',
  'delete.review': '删除评审',
  'unconfirmed.person': '未确认人',
  'confirmed.person': '已确认人',
  'review.detail': '评审详情',
  'relation.name': '关联名称',
  'relation.type': '关联类型',

  'create.dashboard': '创建仪表盘',
  'instrument.name': '仪表盘名称',

  'card.logo': '卡片Logo',
  'card.name.cn': '卡片名称（中文）',
  'card.name.en': '卡片名称（英文）',
  'card.code': '卡片编码',
  'card.desc.cn': '卡片描述（中文）',
  'card.desc.en': '卡片描述（英文）',
  'card.type': '卡片类型',
  'edit.personal.instrument.card': '编辑个人仪表卡片',
  'card.list': '卡片列表',

  'to.do.name': '待办名称',
  'to.do.type': '待办类型',
  'to.do.status': '待办状态',
  'expected.end': '预计完成时间',

  'member.name': '成员名称',
  'edit.members': '编辑成员',

  'report.date': '汇报（年-周）',
  'weekly.report.title': '周报标题',
  'weekly.report.type': '周报类型',
  'weekly.report.title.template': '周报标题模板',
  'weekly.report.content.template': '周报内容模板',

  'report.to': '汇报对象',
  'report.by': '汇报人',
  'report.cc': '抄送人',
  role: '角色',

  'push.time': '发送时间',
  'is.automatic.sending.enabled': '是否启用自动发送',

  'stage.name': '阶段名称',
  quantity: '数量',
  'week.on.month.ratio': '与上周环比',
  'special.remark': '备注(回车保存)',
  'read.count': '阅读数',
  'add.comment': '发表评论',
  'comment.content': '评论内容',

  'chinese.name': '中文名称',
  code: '编码',
  'status.type': '状态类型',
  'status.code': '状态编码',
  'english.name': '英文名称',
  'status.group': '状态组',
  'echo.color': '回显颜色',
  'transferable.status': '可流转状态',
  sort: '排序',
  'clone.to.other.status.group': '克隆到其他状态组',

  'meeting.room.id': '会议室id',
  'meeting.room.name': '会议室名称',
  'meeting.room.capacity': '会议室容量',
  city: '城市',
  building: '建筑',
  floor: '楼层',
  'meeting.room.equipment': '会议室设备',

  sn: '序列号',

  标题: '标题',
  状态: '状态',
  负责人: '负责人',
  起始时间: '起始时间',
  截止时间: '截止时间',
  创建时间: '创建时间',
  创建人: '创建人',
  工作项类型: '工作项类型',
  更新时间: '更新时间',
  更新人名称: '更新人名称',
  操作: '操作',
  优先级: '优先级',
  截止日期: '截止日期',
  所属项目: '所属项目',
  任务名称: '任务名称',

  项目名称: '项目名称',
  团队空间: '团队空间',
  项目负责人: '项目负责人',
  项目干系人: '项目干系人',
  项目周期: '项目周期',
  默认视图: '默认视图',
  团队空间名称: '团队空间名称',
  团队空间成员: '团队空间成员',
  团队空间描述: '团队空间描述',
  里程碑周期: '里程碑周期',
  删除项目: '删除项目',
  项目是否公开: '项目是否公开',
  编辑: '编辑',
  新建需求: '新建需求',
  转产品需求: '转产品需求',
  新建任务: '新建任务',
  删除任务: '删除任务',
  里程碑负责人: '里程碑负责人',
  需求名称: '需求名称',
  所属系统: '所属系统',
  干系人: '干系人',
  描述: '描述',
  需求周期: '需求周期',
  项目参与人: '项目参与人',
  项目创建人: '项目创建人',
  项目创建时间: '项目创建时间',
  里程碑名称: '里程碑名称',
  里程碑: '里程碑',
  系统: '系统',
  开始时间: '开始时间',
  实际完成时间: '实际完成时间',
  项目: '项目',
  主产品经理: '主产品经理',
  公开状态: '公开状态',
  删除需求: '删除需求',
  删除里程碑: '删除里程碑',

  规划周期: '规划周期',
  人天预算: '人天预算',
  版本号: '版本号',
  关联需求任务数: '关联需求任务数',

  类型: '类型',
  参与人: '参与人',
  预估人天: '预估人天',
  开始结束: '开始结束',

  'commit.cnt': '提交次数',
  'merge.cnt': 'Merge次数',
  'push.cnt': 'Push次数',
  'tag.cnt': 'Tag次数',
  'merge.subscribe': '订阅Merge',
  'push.subscribe': '订阅Push',
  'merge.subscribers': 'Merge订阅者',
  'push.subscribers': 'Push订阅者',
  'warehouse.name': '仓库名',
  'related.sn': '关联序列号',
  'commit.email': '提交邮箱',
  'commit.url': '提交url',
  'commit.message': '提交描述',
  'commit.date': '提交时间',
  'commit.branch': '提交分支',
  'git.http.url': 'Git仓库地址',

  'nick.name': '昵称',
  'attendance.date': '考勤日期',
  'attendance.type': '考勤类型',
  'attendance.start.address': '考勤开始地点',
  'attendance.end.address': '考勤结束地点',
  'attendance.start.source': '考勤开始来源',
  'attendance.end.source': '考勤结束来源',
  'attendance.start.result': '考勤开始结果',
  'attendance.end.result': '考勤结束结果',
  'department.name': '部门名称',
  'department.members': '部门成员',
  'location.city': 'Base地',


  'personal.attendance.days': '个人出勤天数',
  'personal.total.working.hours': '个人总工时',
  'personal.average.working.hours': '个人平均工时',
  'department.attendance.person.day': '部门出勤人天',
  'department.total.working.hours': '部门总工时',
  'department.average.working.hours': '部门平均工时',
  'location.city.attendance.person.day': 'Base地出勤人天',
  'location.city.total.working.hours': 'Base地总工时',
  'location.city.average.working.hours': 'Base地平均工时',

  'review.location': '评审地点',
  'review.allLocation': '地点',
  'review.count': '评审数量',
  'bug.count': '缺陷数量',
  'online.bug.count': '线上问题数量',

  'is.have.template': '周报模板',
  'dept.status': '部门状况',

  'total.commit.cnt': '提交总数',
  'requirment.commit.cnt': '需求提交数',
  'bug.commit.cnt': '缺陷提交数',
  'online.bug.commit.cnt': '线上问题提交数',
  'other.commit.cnt': '其他提交数',
  'standard.commit.rate': '规范提交率',


  'related.business': '关联业务领域',
  'related.system': '关联系统',


  'position': '岗位',
  'production.Defects.score': '生产缺陷分',
  'develop.defects.score': '研发缺陷分',
  'working.hours.score': '工时分',
  'average.score.of.production.and.research': '产研平均分',
  'individual.total.score': '个人总分',
  'technical.debt.score': '技术债务分',
  'delivery.efficiency.score': '交付效率分',

  'code.review.report': 'CR报告',

  'status.stage.nameCn': '阶段名称（中文）',
  'status.stage.nameEn': '阶段名称（英文）',
  'status.stage.code': '阶段编码',
  'status.stage.echoColor': '回显颜色',
  'status.stage.sortNum': '排序编号',
  'description': '描述',
  'workflow': '工作流',
  'issue.type': '需求类型',



















};
