export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password(admin/ant.design)',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.username.placeholder': 'Username: admin or user',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password: ant.design',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',

  'pages.common.lastUpdDate': 'Update time',
  'pages.common.lastUpdUserName': 'Updated by',
  'pages.common.option': 'Operation',
  'pages.common.create': 'New',
  'pages.common.edit': 'Edit',

  

  'pages.system.updateForm.schedulingPeriod.timeLabel': 'Start time',
  'pages.system.updateForm.schedulingPeriod.timeRules': 'Please select a start time! ',
  'pages.system.title': 'System',
  'pages.system.name': 'system name',
  'pages.system.systemId': 'System ID (the SystemId associated with Jira)',
  'pages.system.systemKey': 'System Key',
  'pages.system.ruleName': 'The system name is required',
  'pages.system.businessName': 'Business Area',
  'pages.system.businessNameEn': 'Business Field-English',
  'pages.system.leaderUserName': 'system leader',
  'pages.system.businessLeaderUserName': 'Business Party Representative',
  'pages.common.productManagerName': 'Product Manager',
  'pages.common.uiDesignName': 'UI Design',
  'pages.common.developName': 'Backend Development',
  'pages.common.frontWebName': 'front-end development',
  'pages.common.testEngineerName': 'Test Development',
  'pages.common.itSupportName': 'Technical Support',
  'pages.system.systemDesc': 'System Description',
  'pages.system.exception': 'Please enter the reason for the exception! ',
  'pages.system.form.businessName': 'Business field',
  'pages.system.form.systemKey': 'System Key (unique)',
  'pages.system.form.selectBusiness': 'Please select a business area',
  'pages.system.form.inputSystemName': 'Please enter the system name',
  'pages.system.form.inputSystemId': 'Please enter the associated Jira system ID',
  'pages.system.form.inputSystemKey': 'Please enter the system Key',


};
