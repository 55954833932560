// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';






/** 获取详情 */
export async function getCaseDetail(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.BusinessIssueDetail>>('/cccc?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}


export async function createTestModule(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-module/create', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function updTestModule(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-module/update', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function transferTestModule(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-module/transfer-module', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/**
 * 用例转移模块
 * @param body 
 * @param options 
 * @returns 
 */
export async function assignModul(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-case/assign-module', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function changeCase(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-case/common-change', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function assignIssue(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-case/assign-issue', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function listSelectModule(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.ModuleListItem[]>>('/test-module/list-module', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}


export async function delModule(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-module/delete-module?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function delTestCase(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-case/delete-case?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}



/** 获取树 */
export async function getXmindTree(id: string) {
  return request<API.ImileResponse<API.TestCaseMinder>>('/test-case/get-case-xmind-tree?id=' + id, {
    method: 'GET',
  });
}

/** 获取模块树 */
export async function getModuelTree(id: string) {
  return request<API.ImileResponse<API.TestCaseMinder>>('/test-module/get-module-xmind-tree?id=' + id, {
    method: 'GET',
  });
}

// 分页查询
export async function pagedTestCases(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.TestCaseList>>('/test-case/page', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}
// 分页查询模块
export async function pagedTestCasesModule(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.TestCaseList>>('/test-module/page', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}



/** 上传文件 */
export async function uploadCase(body: Object, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/test-case/upload-case', {
    data: body,
    method: 'POST',
    ...(options || {}),
  });
}

/** 根据系统id集合查测试用例*/
export async function listCaseBySystemIds(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/test-case/list-by-systems', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 根据用例id集合查用例 */
export async function listCaseByIds(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/test-case/list-by-ids', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}




