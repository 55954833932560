export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register-result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.systems': 'Search List(systems)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',


  'menu.dashboard.workplace': 'My Workbench',
  'menu.dashboard.weekly': 'My weekly report',
  'menu.dashboard.duty': 'Duty Roster Management',
  'menu.rd': 'R&D Collaboration',
  'menu.rd.system': 'System',
  'menu.rd.issues': 'Requirements Management',
  'menu.rd.process': 'R&D process',
  'menu.rd.process.issues': 'Requirements',
  'menu.rd.process.tasks': 'Tasks',
  'menu.rd.process.bugs': 'Bugs',
  'menu.rd.process.board': 'Kanban',
  'menu.rd.process.statistics': 'Statistics',
  'menu.delivery': 'Delivery Kanban',
  'menu.delivery.view': 'Delivery View',
  'menu.delivery.services': 'Service View',
  'menu.efficiency': 'Efficiency Insight',
  'menu.efficiency.dashboard': 'Demand Delivery Overview',
  'menu.efficiency.scanner': 'Code Quality',
  'menu.question': 'Question Board',
  'menu.question.online': 'Online question pool',
  'menu.question.record': 'Accident Replay Record',
  'menu.tools': 'Tools',
  'menu.tools.sls': 'SLS',
  'menu.tools.clickVisual': 'ClickVisual',
  'menu.tools.polarDB': 'SlowSql',
  'menu.settings': 'Settings',
  'menu.settings.label': 'Label Management',
  'menu.settings.status': 'Status data',
  'menu.settings.statusType': 'Status Management',
  'menu.settings.rejectNode': 'Reject Node Config',

  'menu.test': '测试管理',
  'menu.test.case': '测试用例',
  'menu.test.minder': '用例详情',
  'menu.test.report': '测试报告',
};
