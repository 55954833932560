import {ProLayoutProps} from '@ant-design/pro-components';

/**
 * @name
 */
const Settings: ProLayoutProps & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme:  'light',
  colorPrimary: '#00c479',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '研发效能平台',
  pwa: true,
  // logo: '',
  iconfontUrl: '',
  token: {
    pageContainer: {
      paddingInlinePageContainerContent: 10,
      paddingBlockPageContainerContent: 5
    },


    // 参见ts声明，demo 见文档，通过token 修改样式
    //https://procomponents.ant.design/components/layout#%E9%80%9A%E8%BF%87-token-%E4%BF%AE%E6%94%B9%E6%A0%B7%E5%BC%8F
  },
};


export default Settings;
