import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { uploadFile } from "@/services/common/api";
import { Editor } from '@tinymce/tinymce-react';
import { Image } from 'antd';




export type RichProps = {
  data: any;
  onChange: (value: any) => void;
  onEdit: () => void;
  height: any;
  readOnly: boolean;
  extendControls: any;
  placeholder: string;
};
export interface RichTextHandles {
  description: any;
}
const App = forwardRef<RichTextHandles, RichProps>((props, ref) => {
  const [state, setState] = useState();
  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');





  useEffect(() => {
    setState(props?.data ?? '')
  }, [props?.data, props.readOnly]);

  // 使用useImperativeHandle来向父组件暴露组件内部的方法
  useImperativeHandle(ref, () => ({
    description: state
  }));




  const upload = async function (blobInfo, succFun, failFun) {
    console.log(blobInfo)
    const fd = new FormData()
    fd.append('file', blobInfo.blob())
    fd.append('permissionType', 'public-read')
    const { resultObject } = await uploadFile(fd)
    if (resultObject) {
      succFun(resultObject.fileUrl);
    } else {
      failFun('失败')
    }
  }



  const handleEditorChange = (value: any) => {
    setState(value)
    if (props.onChange) {
      props.onChange(value);
    }
  }


  const handleImageClick = (event) => {
    if (event.target.nodeName === 'IMG') {
      const imageUrl = event.target.src;  // 获取图片的src链接
      setPreviewImage(imageUrl);
      setVisible(true);
    }
  };

  const processContent = (content) => {
    // 创建一个临时的div容器来处理HTML内容
    const div = document.createElement('div');
    div.innerHTML = content;

    // 查找所有外部链接（href属性以 "http" 开头的链接）
    const links = div.querySelectorAll('a[href^="http"]');
    links.forEach(link => {
      // 如果没有target属性，就添加target="_blank"
      if (!link.hasAttribute('target')) {
        link.setAttribute('target', '_blank');
      }
      if (!link.hasAttribute('rel')) {
        link.setAttribute('rel', 'noopener noreferrer');
      }
    });

    // 查找所有图片
    const images = div.querySelectorAll('img');
    images.forEach(img => {
      // 如果没有width属性，或width超出100%，则设置width为100%
      if (!img.style.width && !img.hasAttribute('width') && !img.getAttribute('data-resized')) {
        img.style.width = '100%';
        img.style.height = 'auto';
        img.setAttribute('data-resized', 'true');
      }
    });
    // 返回处理后的HTML内容
    return div.innerHTML;
  }

  window.addEventListener('beforeunload', function (event) {
    console.log('页面即将关闭');
    // 获取所有的键名  
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // 检查键名是否是 TinyMCE 自动保存的数据  
      if (key && key.startsWith('tinymce-autosave-')) {
        // 清除该键名对应的数据  
        localStorage.removeItem(key);
      }
    }

    // 如果需要提示用户确认离开页面，可以设置如下信息  
    // event.preventDefault();  
    // event.returnValue = '';  
  });






  return (<>
    {!props.readOnly && props.extendControls}
    {!props.readOnly && <Editor
      value={state}
      init={{
        height: props.height ?? undefined,
        min_height: 200,
        max_height: 1000,
        // menubar: false,
        selector: '#tinydemo2',
        placeholder: props.placeholder ?? '请输入内容',
        // skin: 'oxide-dark',
        language: 'zh_CN',
        plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em  formatpainter axupimgs paste ' + (props.height ? '' : 'autoresize'),
        toolbar: 'forecolor backcolor bold italic underline strikethrough link   | formatselect  fontsizeselect  | styleselect fontselect  | alignleft aligncenter alignright alignjustify outdent indent|  bullist numlist | blockquote subscript superscript removeformat | table image  charmap emoticons hr pagebreak insertdatetime print preview anchor code codesample | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',

        /*content_css: [ //可设置编辑区内容展示的css，谨慎使用
            '/static/reset.css',
            '/static/ax.css',
            '/static/css.css',
        ],*/
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
        // link_list: [
        //   { title: '预置链接1', value: 'http://www.tinymce.com' },
        //   { title: '预置链接2', value: 'http://tinymce.ax-z.cn' }
        // ],
        // image_list: [
        //   { title: '预置图片1', value: 'https://www.tiny.cloud/images/glyph-tinymce@2x.png' },
        //   { title: '预置图片2', value: 'https://www.baidu.com/img/bd_logo1.png' }
        // ],
        // image_class_list: [
        //   { title: 'None', value: '' },
        //   { title: 'Some class', value: 'class-name' }
        // ],
        importcss_append: true,
        //自定义文件选择器的回调内容
        file_picker_callback: function (callback, value, meta) {
          if (meta.filetype === 'file') {
            callback('https://www.baidu.com/img/bd_logo1.png', { text: 'My text' });
          }
          if (meta.filetype === 'image') {
            callback('https://www.baidu.com/img/bd_logo1.png', { alt: 'My alt text' });
          }
          if (meta.filetype === 'media') {
            callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.baidu.com/img/bd_logo1.png' });
          }
        },
        paste_data_images: true,
        paste_retain_style_properties: "color font-size",
        // paste_word_valid_elements: true,
        images_upload_handler: upload,
        paste_filter_drop: false,
        default_link_target: "_blank",
        paste_postprocess: (plugin, args) => {
          const urlPattern = /^(https?:\/\/[^\s]+)/;
          // 使用正则表达式检查并替换 URL 为超链接
          args.node.childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE && urlPattern.test(node.textContent)) {
              const url = node.textContent.trim();
              const anchor = document.createElement('a');
              anchor.href = url;
              anchor.target = '_blank';
              anchor.textContent = url;

              // 替换文本节点为超链接元素
              node.parentNode.replaceChild(anchor, node);
            }
          });
        },
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        toolbar_sticky: true,
        autosave_ask_before_unload: false,
        setup: function (editor) {
          // editor.on('init', function () {
          //   // 获取当前编辑器内容并处理
          //   const currentContent = editor.getContent();
          //   console.log('currentContent', currentContent)
          //   const updatedContent = processContent(currentContent);

          //   // 设置处理后的内容到编辑器
          //   editor.setContent(updatedContent);
          // });
          // 监听插入图片
          editor.on('BeforeSetContent', function (e) {
            if (e && e.content) {
              e.content = processContent(e.content);
            }
          });
        }
      }} Í
      tinymceScriptSrc={'/tinymce/tinymce.min.js'}
      onEditorChange={handleEditorChange}
    />}
    {props.readOnly &&
      <Editor
        value={state}
        // disabled={true}
        init={{
          height: props.height ?? undefined,
          min_height: 200,
          max_height: 1000,
          menubar: false,
          plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em formatpainter axupimgs paste ' + (props.height ? '' : 'autoresize'),
          toolbar: '',
          language: 'zh_CN',
          toolbar_sticky: true,
          autosave_ask_before_unload: false,
          setup: (editor) => {
            editor.on('init', () => {
              // 初始条件（即第一次加载时）的设置
              const editorBody = editor.getBody();
              editorBody.setAttribute('contenteditable', false);

              // const scrollHeight = editorBody.clientHeight;
              // const editorContainer = editor.getContainer();

              // console.log(scrollHeight)

              // if (scrollHeight < 1000) {
              //   editorContainer.style.height = `${scrollHeight}px`;
              // } else {
              //   editorContainer.style.height = '1000px';
              // }
            });
            editor.on('BeforeSetContent', function (e) {
              e.content = processContent(e.content);
            });
            editor.on('click', handleImageClick);
            editor.on('dblclick', props.onEdit);
          },
          content_style: "body {pointer - events: auto; cursor: default;}",
        }}
        tinymceScriptSrc={'/tinymce/tinymce.min.js'}

      />}
    <Image.PreviewGroup
      preview={{
        visible: visible,
        onVisibleChange: vis => setVisible(vis),
        current: 0
      }}
    >
      <Image src={previewImage} style={{ display: 'none' }} />
    </Image.PreviewGroup>
  </>
  )
})

export default App;

