import { BellOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Popover, Spin, Tabs } from 'antd';
import useMergedState from 'rc-util/es/hooks/useMergedState';
import React, { useState } from 'react';
import type { NoticeIconTabProps } from './NoticeList';
import NoticeList from './NoticeList';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { useIntl } from "@@/exports";

export type NoticeIconProps = {
  count?: number;
  bell?: React.ReactNode;
  className?: string;
  loading?: boolean;
  onClear?: (tabName: string, tabKey: string) => void;
  onItemClick?: (item: API.NoticeIconItem, tabProps: NoticeIconTabProps) => void;
  onViewMore?: (tabProps: NoticeIconTabProps, e: MouseEvent) => void;
  style?: React.CSSProperties;
  onPopupVisibleChange?: (visible: boolean) => void;
  popupVisible?: boolean;
  clearText?: string;
  viewMoreText?: string;
  clearClose?: boolean;
  emptyImage?: string;
  children?: React.ReactElement<NoticeIconTabProps>[];
};

const NoticeIcon: React.FC<NoticeIconProps> & {
  Tab: typeof NoticeList;
} = (props) => {
  const [type, setType] = useState<string>('message');
  const intl = useIntl();
  const tabsClass = useEmotionCss(({ token }) => {
    return {}
    // }
  });


  const getNotificationBox = (): React.ReactNode => {
    const {
      children,
      loading,
      onClear,
      onItemClick,
      onViewMore,
      clearText,
      viewMoreText,
    } = props;
    if (!children) {
      return null;
    }
    const items: Array<any> = [];
    React.Children.forEach(children, (child: React.ReactElement<NoticeIconTabProps>): void => {
      if (!child) {
        return;
      }
      const { list, title, count, tabKey, showClear, showViewMore } = child.props;
      const len = list && list.length ? list.length : 0;
      const msgCount = count || count === 0 ? count : len;
      const tabTitle: string = msgCount > 0 ? `${title} (${msgCount})` : title;
      items.push(
        {
          key: tabKey,
          label: tabTitle,
          children: <NoticeList
            clearText={clearText}
            viewMoreText={viewMoreText}
            list={list}
            tabKey={tabKey}
            onClear={(): void => onClear && onClear(title, tabKey)}
            onClick={(item): void => onItemClick && onItemClick(item, child.props)}
            onViewMore={(event): void => onViewMore && onViewMore(child.props, event)}
            showClear={showClear}
            showViewMore={showViewMore}
            title={title}
          />,
        }
      );
    });
    return (
      <>
        <Spin spinning={loading} delay={300}>
          <Tabs
            style={{
              position: "relative",
              width: 336,
            }}
            className={useEmotionCss(({ token }) => {
              return {
                '.ant-tabs-nav': {
                  margin: '0 0 5px 0'
                }
              }
            })}
            activeKey={type}
            onChange={setType}
            centered
            items={items}
          />
        </Spin>
      </>
    );
  };

  const { count } = props;

  const [visible, setVisible] = useMergedState<boolean>(false, {
    value: props.popupVisible,
    onChange: props.onPopupVisibleChange,
  });
  // const noticeButtonClass = className;
  const notificationBox = getNotificationBox();
  const trigger = (
    <div style={{
      display: 'flex',
      height: 26,
    }}>
      <Badge count={count} size="small">
        <BellOutlined style={{
          fontSize: 16,
          color: '#8c8c8c'
        }} />
      </Badge>
    </div>
  );
  if (!notificationBox) {
    return trigger;
  }
  return (
    <Popover trigger="click" open={visible} onOpenChange={(e) => { setVisible(e) }} content={notificationBox} arrow={false} placement="bottomRight" style={{
      position: 'relative',
      width: 343,
    }}>
      {trigger}
    </Popover>
  );
};

NoticeIcon.defaultProps = {
  emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
};

NoticeIcon.Tab = NoticeList;

export default NoticeIcon;
