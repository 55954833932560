import { Radio, Select, Spin, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { pagedSystems } from "@/services/rd/system/api";
import { history, useModel } from "@@/exports";
import { setUserSystemCache } from "@/services/common/api";
import { BulbFilled } from "@ant-design/icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";


const filterOption = (input: string, option: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const SystemSelect: React.FC = () => {
  const [checked, setChecked] = useState(true);
  const [defaultSystem, setDefaultSystem] = useState('');
  const { initialState, setInitialState } = useModel('@@initialState');

  // const {  run } = useRequest((id)=>{(id)}, { manual: true })





  const changeSystem = async (val) => {
    //清除bug的当前模块
    localStorage.setItem('testbugModule', 'all')
    localStorage.setItem('prodbugModule', 'all')
    const { resultCode, resultObject } = await setUserSystemCache(val);
    if (resultCode === 'success') {
      setDefaultSystem(val)
      setInitialState((preInitialState) => ({
        ...preInitialState,
        defaultSystem: resultObject
      }));
      // window.location.reload();
    }

  }


  const onChange = (checked: boolean) => {
    let settings = {
      ...initialState?.settings
    }
    if (checked) {
      settings.navTheme = 'light'
    } else {
      settings.navTheme = 'realDark'
    }
    setChecked(checked)
    localStorage.setItem('navTheme', settings.navTheme);
    setInitialState((preInitialState) => ({
      ...preInitialState,
      settings
    }));
  };

  useEffect(() => {
    const defaultSystemId = initialState?.defaultSystem?.systemDTO?.systemId || '';
    // console.log(defaultSystemId)
    if (!defaultSystemId && initialState?.systemList?.length > 0) {
      changeSystem(initialState?.systemList[0].systemId)
    } else {
      setDefaultSystem(defaultSystemId)
    }

    if (!localStorage.getItem('navTheme') || localStorage.getItem('navTheme') === 'light') {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [defaultSystem])



  return (
    <>
      <Select
        popupMatchSelectWidth={150}
        value={defaultSystem.toString()}
        // bordered={false}
        size={"large"}
        showSearch={true}
        placeholder="请选择系统"
        onSelect={changeSystem}
        filterOption={filterOption}
        options={initialState?.systemList?.map((item) => ({ label: item.systemName, value: item.systemId }))}
      />
      {/* <Tooltip title={'主题颜色'}>
        <Switch defaultChecked onChange={onChange} checked={checked} size={'small'} style={{ borderRadius: 10, paddingBlock: 0, paddingInline: 0 }}
          checkedChildren={<FontAwesomeIcon icon={faSun} />}
          unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
        />
      </Tooltip> */}
    </>
  );
};

export default SystemSelect;
