import { Avatar, Modal, Space, Table, Tooltip, Button, Tag } from 'antd';
import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, useModel, useRequest } from "@@/exports";
import { getMeetingRooms, getMeetingRoomLinkageInfo } from "@/services/rd/issues/api";
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { CheckOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useEmotionCss } from "@ant-design/use-emotion-css";


export type IssueTableProps = {
  onCancel: (flag?: boolean) => void;
  onSubmit: any;
  isModalOpen: boolean;
  reviewStartTime: any;
  reviewEndTime: any;
};




const App: React.FC<IssueTableProps> = (props) => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();

  // const [rooms, setRooms] = useState<[]>([]);
  const [meetingRoomLinkageInfo, setMeetingRoomLinkageInfo] = useState<{}>({});

  const [newCity, setNewCity] = useState(undefined);
  const [oldCity, setOldCity] = useState(undefined);

  const [newBuilding, setNewBuilding] = useState(undefined);
  const [oldBuilding, setOldBuilding] = useState(undefined);

  const [newFloor, setNewFloor] = useState(undefined);
  const [oldFloor, setOldFloor] = useState(undefined);

  const [buildings, setBuildings] = useState<[]>([]);
  const [floors, setFloors] = useState<[]>([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [roomInfo, setRoomInfo] = useState({});



  // 获取会议室位置联动信息
  const { run: runMeetingRoomLinkageInfo } = useRequest(() => {
    return getMeetingRoomLinkageInfo()
  }, {
    manual: true,
    onSuccess: (res) => {
      setMeetingRoomLinkageInfo(res);
    }
  });

  useEffect(() => {
    if (props.isModalOpen) {
      // setSelectedRows([]);
      // setRoomInfo({});
      // setSelectedKeys([]);
      // actionRef?.current?.reload();
      runMeetingRoomLinkageInfo();
    }
  }, [props.isModalOpen]);

  const tableClass = useEmotionCss(({ token }) => {
    return {
      '.ant-pro-table-list-toolbar-container': {
        paddingBlock: 0,
        paddingInline: 5
      },
      // '.ant-pro-card .ant-pro-card-body': {
      //   paddingInline: 5
      // } ,
      '.ant-pro-table-extra': {
        marginBlockEnd: 0
      },

    }
  })


  const columns: ProColumns[] = [
    {
      title: <FormattedMessage id="meeting.room.name" defaultMessage="会议室名称" />,
      dataIndex: 'name',
      ellipsis: true,
      align: 'left',
      search: false,
      width: 200,
    },

    {
      title: <FormattedMessage id="city" defaultMessage="城市" />,
      dataIndex: 'city',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: meetingRoomLinkageInfo?.citys?.map((item: any) => ({
        text: item,
        value: item,
      })),
      render: (_, row) => {
        return <span>{row?.city}</span>
      },
    },

    {
      title: <FormattedMessage id="building" defaultMessage="建筑" />,
      dataIndex: 'building',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: buildings?.map((item: any) => ({
        text: item,
        value: item,
      })),
      render: (_, row) => {
        return <span>{row?.building}</span>
      },
    },

    {
      title: <FormattedMessage id="floor" defaultMessage="楼层" />,
      dataIndex: 'floor',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: floors?.map((item: any) => ({
        text: item,
        value: item,
      })),
      render: (_, row) => {
        return <span>{row?.floor}</span>
      },
    },

    {
      title: <FormattedMessage id="meeting.room.capacity" defaultMessage="会议室容量" />,
      dataIndex: 'capacitySign',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: [{ value: 0, text: "12人以下" }, { value: 1, text: "13~20人" }, { value: 2, text: "20人以上" }],
      render: (_, row) => {
        return <Tag>{row?.capacity}</Tag>
      },
    },

    {
      title: <FormattedMessage id="meeting.room.equipment" defaultMessage="会议室设备" />,
      dataIndex: 'equipmentIds',
      ellipsis: true,
      align: 'left',
      search: false,
      width: 120,
      filterMultiple: true,
      filters: [{ value: 1, text: "电视" }, { value: 2, text: "电话" }, { value: 3, text: "投影" }
        , { value: 4, text: "白板" }, { value: 5, text: "视频" }
      ],
      render: (_, row) => {
        return <span>{row?.equipmentNames}</span>
      },
    },

  ];



  return (
    <>
      <Modal title="请选择当前评审时间段空闲的会议室" open={props.isModalOpen} okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }} width={'70%'} onCancel={props.onCancel}
      >

        <ProTable
          actionRef={actionRef}
          tableStyle={{ fontSize: 10 }}
          rowKey='meetingRoomId'
          className={tableClass}
          formRef={formRef}
          search={false}
          headerTitle={(
            <Button
              size={"small"}
              type="primary"
              key="primary"
              onClick={async () => {
                await props.onSubmit(roomInfo);
              }}
            >
              <CheckOutlined /> <FormattedMessage id="pages.common.notarize" defaultMessage="确认" />
            </Button>
          )}
          request={async (
            params: T & {
            },
            sort: any,
            filter: any,) => {

            setBuildings([]);
            setFloors([]);

            const param = {
              startTime: props?.reviewStartTime,
              endTime: props?.reviewEndTime,
              equipment: filter?.equipmentIds,
              capacitySign: filter?.capacitySign?.[0],
              city: filter?.city?.[0],
              building: (filter?.city?.[0] === oldCity) ? filter?.building?.[0] : undefined,
              floor: (filter?.city?.[0] === oldCity && filter?.building?.[0] === oldBuilding) ? filter?.floor?.[0] : undefined,
            }

            setOldCity(param?.city);
            setOldBuilding(param?.building);
            setOldFloor(param?.floor);

            if (param?.city !== undefined) {
              setBuildings(meetingRoomLinkageInfo?.buildingsMap[param?.city]);
            }

            if (param?.building !== undefined) {
              setFloors(meetingRoomLinkageInfo?.floorsMap[param?.building]);
            }

            const resp = await getMeetingRooms(param);
            return {
              data: resp?.resultObject,
              success: true,
              total: resp?.resultObject.length,
            }
          }}
          columns={columns}

          rowSelection={{
            onChange: (_, selectedRows) => {
              setSelectedRows(selectedRows);
              if (selectedRows.length > 0) {
                const roomName = selectedRows[0].name + "(" + selectedRows[0].building + "-" + selectedRows[0].floor + ")";
                const roomId = selectedRows[0].meetingRoomId;
                setRoomInfo({ roomName, roomId })
                setSelectedKeys([roomId]);
              }
            },
            // selectedRowKeys: selectedKeys,
            getCheckboxProps: (record) => ({
              // 当有行被选中时，禁用其他行的复选框
              disabled: selectedRows.length > 0 && selectedRows?.filter(u => u?.meetingRoomId !== record.meetingRoomId).length > 0,
            }),
          }}

          defaultSize={"small"}
          scroll={{ y: 300 }}
        />

      </Modal>
    </>
  );
};

export default App;
