import {
  ModalForm,
  ProFormDateRangePicker,
  ProFormGroup,
  ProFormInstance,
  ProFormList,
  ProFormSelect,
  ProFormText,
  ProFormItem,
  ProFormTextArea,
  ProFormDigit,
} from '@ant-design/pro-components';
import { useIntl } from '@umijs/max';
import React, { useEffect, useRef, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { AutoComplete, Button, Divider, Form, Input, message, Modal, Space, Tooltip } from 'antd';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import 'braft-editor/dist/index.css';
import { getDictData, listUsers, uploadFile } from '@/services/common/api';
import { useModel, useRequest } from '@@/exports';
import {
  listSelectIssue,
  listIssueBasicInfo,
  addLabelTag,
  getMaxRangeDateByissueIds,
} from '@/services/rd/issues/api';
import { selectListV2 } from '@/services/settings/label';

import { FormInstance } from 'antd/lib';
import { RcFile } from 'antd/es/upload';
import { useEmotionCss } from '@ant-design/use-emotion-css';
import moment from 'moment';
import { listIssueByPlanIds, listPlanBySystemIds } from '@/services/rd/sprint/api';
import {
  createBranch,
  getTaskServiceByIssueIds,
  listGitByService,
  listServiceByServiceType,
  listTaskServiceByIssueIds,
} from '@/services/rd/task/api';
import TimeLineLog from '@/components/TimeLineLog';
import MyTinyMCEEditor from '@/components/MyTinyMCEEditor';
import issue from '@/services/project/issue';

export type FormValueType = {
  time: any;
} & Partial<API.TaskDetail>;

export type UpdateFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalOpen: boolean;
  isCreated: boolean;
  isStash: boolean;
  detail: any;
  complexRateTypeList: any;
  priorityLevelList: any;
  rdTaskType: any;
  rdTaskProgress: any;
  // serviceType: any;
  issueIds: any;
  planId: any;
  isDisabledPlan: boolean;
  isDisabledIssue: boolean;
  isBind: boolean;
  systemIdList: any;
  taskLabels: any;
  issueName: any;
  isIssueRequired: boolean;
};

const TaskModalForm: React.FC<UpdateFormProps> = (props) => {
  const intl = useIntl();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const { initialState } = useModel('@@initialState');
  const taskRef = useRef<ProFormInstance>();
  const tagFormRef = React.useRef<FormInstance>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [state, setState] = useState('');
  const [showOthers, setShowOthers] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disableDate, setDisableDate] = useState<any[]>([]);
  const [tagList, setTagList] = useState<[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: serviceTypeList, run: runServiceType } = useRequest(
    () => {
      return getDictData('service_type');
    },
    { manual: true },
  );

  const { data: userList, run: runUserList } = useRequest(listUsers, { manual: false });

  // 查需求下拉列表
  const { data: selectIssueList, run: runSelectIssueList } = useRequest(
    (param) => {
      if (param?.setlectType === 0) {
        return listSelectIssue(param);
      } else {
        return listIssueByPlanIds(param);
      }
    },
    { manual: true },
  );

  // 根据系统查规划下拉列表
  const { data: selectPlanList, run: runSelectPlanList } = useRequest(
    (param) => {
      return listPlanBySystemIds(param);
    },
    { manual: true },
  );
  // 根据系统查规划下拉列表
  const { data: gitList, run: runGitList } = useRequest(
    (serviceName) => {
      return listGitByService(serviceName);
    },
    { manual: true },
  );

  const { run: runMaxRangeDate } = useRequest(
    (ids) => {
      return getMaxRangeDateByissueIds(ids);
    },
    {
      manual: true,
      onSuccess: (dates) => {
        if (dates) {
          setDisableDate([moment(dates?.[0]), moment(dates?.[1])]);
        }
      },
    },
  );

  // 系统下拉列表
  // const { data: systemList, run: runSystemList } = useRequest(() => {
  //   return listChangeSystem()
  // }, { manual: false })

  // // 获取操作日志
  // const { data: opLog, run: runOpLog } = useRequest((opType) => {
  //   const param = {
  //     relationType: 'ISSUE',
  //     relationId: props.detail?.id,
  //     opType: opType
  //   }
  //   return listIssueOpLog(param)
  // }, { manual: true })

  // 添加标签
  const addTag = async (values: any) => {
    values.labelType = '2';
    const { resultCode, msg } = await addLabelTag(values);
    if (resultCode === 'success') {
      const { resultObject } = await selectListV2('2');
      setTagList(resultObject);
      tagFormRef.current?.resetFields();
      message.success('添加成功');
    } else {
      message.error(msg);
    }
  };

  // 系统变更时，刷新各个下拉数据
  const changeSystem = async (systemIds) => {
    taskRef.current?.setFieldValue('issueIdList', null);
    taskRef.current?.setFieldValue('planId', null);

    // 刷新需求下拉列表数据
    const param = {
      systemIdList: systemIds,
      issueIds: props?.isCreated ? null : props.detail?.issueIds,
      issueStatusType: props?.isCreated ? 1 : null,
      setlectType: 0,
    };
    runSelectIssueList(param);

    // 刷新规划下拉列表数据
    const planParam = {
      systemIds: systemIds,
    };
    runSelectPlanList(planParam);
  };

  // 系统变更时，刷新各个下拉数据
  const changePlan = (planId: any) => {
    taskRef.current?.setFieldValue('issueIdList', null);

    // 刷新需求下拉列表数据
    if (planId) {
      const param = {
        planIds: [planId],
        issueStatusType: props?.isCreated ? 1 : null,
        setlectType: 1,
      };
      runSelectIssueList(param);
    } else {
      const param = {
        systemIdList:
          taskRef.current?.getFieldValue('systemIdList')?.length > 0
            ? taskRef.current?.getFieldValue('systemIdList')
            : ['null'],
        issueIds: props?.isCreated ? null : props.detail?.issueIds,
        issueStatusType: props?.isCreated ? 1 : null,
        setlectType: 0,
      };
      runSelectIssueList(param);
    }
  };

  // const BraftEditorClass = useEmotionCss(({ token }) => {
  //   return {
  //     '.bf-content': {
  //       height: '320px'
  //     },
  //   }
  // })

  // const CommentClass = useEmotionCss(({ token }) => {
  //   return {
  //     '.ant-timeline-item-label': {
  //       width: '130px !important'
  //     },
  //     '.ant-timeline-item-tail': {
  //       insetInlineStart: '140px !important'
  //     },
  //     '.ant-timeline-item-head': {
  //       insetInlineStart: '140px !important'
  //     },
  //     '.ant-timeline-item-content': {
  //       insetInlineStart: '130px !important',
  //       width: 'calc(100% - 170px) !important'
  //     }
  //   }
  // })

  // const items: TabsProps['items'] = [
  //   {
  //     key: '',
  //     label: '全部记录',
  //     children: ''
  //   },
  //   {
  //     key: 'CHANGE_STATUS',
  //     label: '状态变更',
  //     children: ''
  //   },
  //   {
  //     key: 'COMMENT',
  //     label: '评论',
  //     children: ''
  //   },
  // ];

  const setFields = () => {
    taskRef.current?.setFieldsValue({
      taskName: props.detail?.taskName || '',
      issueIdList: props.detail?.issueIdList ? props.detail?.issueIdList : [] || [],
      taskType: props.detail?.taskType || '',
      taskLeader: props.detail?.taskLeader || '',
      time: [props.detail?.startDate, props.detail?.endDate] || [],
      complexRate: props.detail?.complexRate?.toString() || '',
      priorityLevel: props.detail?.priorityLevel?.toString() || '',
      id: props.detail?.id || '',
      description: props.detail?.description || '',
      estimatedPersonDay: props.detail?.estimatedPersonDay || '',
      taskParticipants: props.detail?.taskParticipants || [],
      taskProgress: props.detail?.taskProgress.toString() || '',
      serviceParamList:
        props.detail?.serviceParamList?.map((item) => {
          return { ...item, isExitSelectBranch: true };
        }) || [],
      planId: props.detail?.planId || '',
      systemIdList: props.detail?.systemIdList || [],
      labelCodes: props?.detail?.labelCodes || [],
      remark: props?.detail?.remark,
    });
    setFileList(props.detail?.uploadAttachments);
    setState(props.detail?.description);
  };

  useEffect(() => {
    if (!props.updateModalOpen) {
      return;
    }

    setIsSubmitting(false);
    setTagList(props?.taskLabels);

    if (props.isCreated) {
      if (initialState?.defaultSystem?.systemDTO?.systemId !== 'all') {
        // 刷新规划下拉列表数据
        const planParam = {
          systemIds: [initialState?.defaultSystem?.systemDTO?.systemId],
        };
        runSelectPlanList(planParam);

        if (!props?.planId) {
          runSelectIssueList({
            name: '',
            issueIds: props.issueIds?.length > 0 ? props.issueIds : null,
            systemIdList:
              initialState?.defaultSystem?.systemDTO?.systemId !== 'all'
                ? [initialState?.defaultSystem?.systemDTO?.systemId]
                : null,
            issueStatusType: props?.isCreated && props.issueIds?.length <= 0 ? 1 : null,
            setlectType: 0,
          });
          if (props.issueIds?.length > 0) {
            runMaxRangeDate(props.issueIds);
          }
        } else {
          const param = {
            planIds: [props?.planId],
            issueStatusType: props?.isCreated ? 1 : null,
            setlectType: 1,
          };
          runSelectIssueList(param);
        }
      } else {
        // 刷新规划下拉列表数据
        const planParam = {
          systemIds: props?.systemIdList?.length > 0 ? props?.systemIdList : [null],
        };
        runSelectPlanList(planParam);

        if (!props?.planId) {
          runSelectIssueList({
            name: '',
            issueIds: props.issueIds?.length > 0 ? props.issueIds : null,
            systemIdList:
              initialState?.defaultSystem?.systemDTO?.systemId !== 'all'
                ? [initialState?.defaultSystem?.systemDTO?.systemId]
                : null,
            issueStatusType: props?.isCreated && props.issueIds?.length <= 0 ? 1 : null,
            setlectType: 0,
          });
          if (props.issueIds?.length > 0) {
            runMaxRangeDate(props.issueIds);
          }
        } else {
          const param = {
            planIds: [props?.planId],
            issueStatusType: props?.isCreated ? 1 : null,
            setlectType: 1,
          };
          runSelectIssueList(param);
        }
      }

      setFileList([]);
      setState('');
    }

    if (!props.isCreated) {
      setFields();
      runSelectIssueList({
        name: '',
        issueIds: props.detail?.issueIdList,
        systemIdList:
          taskRef.current?.getFieldValue('systemIdList')?.length > 0
            ? taskRef.current?.getFieldValue('systemIdList')
            : ['null'],
        issueStatusType: props?.isCreated ? 1 : null,
        setlectType: 0,
      });

      // 刷新规划下拉列表数据
      const planParam = {
        systemIds:
          taskRef.current?.getFieldValue('systemIdList')?.length > 0
            ? taskRef.current?.getFieldValue('systemIdList')
            : ['null'],
      };
      runSelectPlanList(planParam);
      if (props.detail?.issueIdList?.length > 0) {
        runMaxRangeDate(props.detail?.issueIdList);
      }
      // runOpLog('');
    }

    runServiceType();
  }, [props.updateModalOpen, props.detail]);

  const onChange: UploadProps['onChange'] = async ({ file, fileList: newFileList }) => {
    if (!file.status) {
      newFileList.splice(fileList.indexOf(file), 1);
    }
    if (file.status === 'done') {
      let obj = {
        uid: newFileList[newFileList.length - 1].uid,
        name: file.response.fileName,
        status: newFileList[newFileList.length - 1].status,
        url: file.response.fileUrl,
        ...file.response,
      };
      newFileList[newFileList.length - 1] = obj;
    } else if (file.status === 'error') {
      message.error(`${file.name} 文件上传失败`);
    }
    setFileList(newFileList);
  };
  const customRequest: UploadProps['customRequest'] = async ({ file, onSuccess, onError }) => {
    const fd = new FormData();
    fd.append('file', file);
    await uploadFile(fd)
      .then((resp) => {
        // 上传成功时，调用 onSuccess 并传递服务器返回的文件 URL
        onSuccess(resp?.resultObject);
      })
      .catch((error) => {
        // 上传失败时，调用 onError 并传递错误信息
        onError(error);
      });
  };

  const beforeUpload = (file: RcFile, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      // console.log(fileList.indexOf(file))
      message.error('上传文件不能超过5MB');
      return false;
    }
    return true;
  };

  return (
    <ModalForm
      formRef={taskRef}
      submitter={{ submitButtonProps: { loading: isSubmitting } }}
      title={
        <Space>
          {props.isCreated
            ? intl.formatMessage({
                id: 'pages.common.create',
                defaultMessage: '创建',
              })
            : intl.formatMessage({
                id: 'pages.common.edit',
                defaultMessage: '编辑',
              })}
          <span style={{ color: 'orange', fontSize: 12 }}>
            （注：任务时间不能超过需求起始至交付时间段，单个任务不超过三天，超过三天的任务请按实际情况拆解）
          </span>
        </Space>
      }
      width="880px"
      open={props.updateModalOpen}
      className={useEmotionCss(({ token }) => {
        return {
          '.ant-pro-form-list-action': {
            marginBlockEnd: 0,
          },
        };
      })}
      onFinish={async (value) => {
        if (isSubmitting) {
          return; // 如果正在提交，则阻止重复提交
        }
        if (value?.description?.length >= 65535) {
          message.error('图片上传错误，请重试！');
          return;
        }
        setIsSubmitting(true);
        // if (commentContent) {
        //   coment();
        // }
        console.log(value);
        let flag = false;
        const countMap = {};
        value?.serviceParamList?.forEach((item) => {
          if (item?.gitBranch && !item?.isExitSelectBranch) {
            flag = true;
          }
          const identifier = `${item.serviceType}_${item.serviceName}`;
          if (countMap[identifier]) {
            countMap[identifier] += 1;
          } else {
            countMap[identifier] = 1;
          }
        });
        if (flag) {
          message.error('存在未创建的分支，请点击分支后的“ + ”创建后再保存任务');
          setIsSubmitting(false);
          return;
        }
        const duplicates: string | any[] = [];
        value?.serviceParamList?.forEach((item) => {
          const identifier = `${item.serviceType}_${item.serviceName}`;
          if (countMap[identifier] > 1) {
            // 检查duplicates中是否已经包含了该项，避免重复添加
            const isAlreadyAdded = duplicates?.some(
              (duplicate) =>
                duplicate.serviceType === item.serviceType &&
                duplicate.serviceName === item.serviceName,
            );
            if (!isAlreadyAdded) {
              duplicates.push(`${item.serviceName}`);
            }
          }
        });
        if (duplicates?.length > 0) {
          const uniqueList = [...new Set(duplicates)];
          message.error('存在相同的服务，请自行Merge到一个分支后保存，服务分别是' + uniqueList);
          setIsSubmitting(false);
          return;
        }

        value.startDate = value?.time[0];
        value.endDate = value?.time[1];
        // value.description = myBrafrEditorRef?.current?.description;
        // value.issueIdList = value.issueIdList ? [value.issueIdList] : null
        props.onSubmit(value);
      }}
      onOpenChange={(open) => {
        !open && props.onCancel();
      }}
      modalProps={{ destroyOnClose: true }}
    >
      <ProFormText
        // colProps={{ md: 12, xl: 8 }}
        name="taskName"
        label={intl.formatMessage({
          id: 'pages.task.name',
          defaultMessage: '任务名称',
        })}
        initialValue={props?.issueName}
        width={810}
        bordered={false}
        rules={[
          {
            required: true,
          },
        ]}
        fieldProps={{
          maxLength: 64, // 限制用户输入不超过64个字符
        }}
      />
      <ProFormGroup>
        <ProFormSelect
          mode={'multiple'}
          disabled={(props.isDisabledIssue || props.isDisabledPlan) && props.isBind}
          showSearch={true}
          name="systemIdList"
          width={390}
          tooltip={'系统与Sprint、需求下拉框会有联动效果'}
          label={intl.formatMessage({
            id: 'pages.system.title',
            defaultMessage: '系统',
          })}
          rules={[
            {
              required: true,
              message: '请选择系统',
            },
          ]}
          onChange={(row) => changeSystem(row)}
          initialValue={
            initialState?.defaultSystem?.systemDTO?.systemId === 'all'
              ? props?.systemIdList
              : props.isDisabledPlan
              ? props?.systemIdList
              : [initialState?.defaultSystem?.systemDTO?.systemId]
          }
          options={initialState?.systemList
            ?.filter((u) => u.systemId !== 'all')
            ?.map((item) => ({ label: item.systemName, value: item.systemId, ...item }))}
        />
        <ProFormSelect
          showSearch={true}
          name="taskType"
          width={390}
          disabled={!props.isCreated}
          label={intl.formatMessage({
            id: 'pages.task.type',
            defaultMessage: '任务类型',
          })}
          onChange={(row) => {}}
          rules={[{ required: true }]}
          options={props.rdTaskType?.rd_task_type?.map((item: any) => ({
            label: item.dictLable,
            value: item.dictValue,
          }))}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect
          disabled={props.isDisabledPlan && props.isBind}
          showSearch={true}
          width={390}
          name="planId"
          label={
            <Space>
              {intl.formatMessage({
                id: 'pages.task.sprint',
                defaultMessage: 'Sprint',
              })}
              <Tooltip title={<div>Sprint下拉选择只有筛选需求的作用，任务只会与需求直接关联</div>}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
          onChange={(row) => changePlan(row)}
          initialValue={props.planId ? props.planId : ''}
          options={selectPlanList?.map((item: any) => ({
            label: item.planName,
            value: item.id,
          }))}
        />

        <ProFormSelect
          disabled={props.isDisabledIssue && props.isBind}
          showSearch={true}
          // colProps={{ md: 12, xl: 8 }}
          name="issueIdList"
          mode={'multiple'}
          width={390}
          rules={[{ required: props.isIssueRequired }]}
          label={
            intl.formatMessage({
              id: 'pages.task.issues',
              defaultMessage: '需求',
            }) + '（选择服务分支必须选择需求）'
          }
          initialValue={props.issueIds}
          options={selectIssueList?.results?.map((item: any) => ({
            label: item.issueName,
            value: item.id,
          }))}
          onChange={async (...id) => {
            if (!taskRef.current?.getFieldValue('taskName')) {
              taskRef?.current?.setFieldValue('taskName', id[1][0]?.title);
            }
            if (id[0] && id[0]?.length > 0) {
              runMaxRangeDate(id[0]);
            } else {
              setDisableDate([]);
            }

            const { resultObject } = await listTaskServiceByIssueIds(id[0]);
            let listNew: any[] = [];
            if (resultObject) {
              let sList = resultObject?.map((item) => {
                return { ...item, isExitSelectBranch: true };
              });
              listNew = sList;
            }
            // else {
            //   let sList = [{
            //     serviceType: '',
            //     serviceName: '',
            //     gitBranch: '',
            //     isExitBranch: false
            //   }]
            //   listNew = sList
            // }
            taskRef?.current?.setFieldValue('serviceParamList', listNew);
          }}
          fieldProps={{
            onSearch: (name) => {
              if (!name) {
                return;
              }
              runSelectIssueList({
                name: name,
                issueIds: props.detail?.issueIdList ? props.detail?.issueIdList : null,
                systemIdList:
                  taskRef.current?.getFieldValue('systemIdList').length > 0
                    ? taskRef.current?.getFieldValue('systemIdList')
                    : null,
                planIds: props?.planId ? [props?.planId] : [],
                issueStatusType: props?.isCreated ? 1 : null,
                setlectType: props?.planId ? 1 : 0,
              });
            },
          }}
        />
      </ProFormGroup>

      <ProFormGroup>
        <ProFormSelect
          colProps={{ md: 12, xl: 8 }}
          showSearch={true}
          name="taskLeader"
          disabled={!props.isCreated}
          width={390}
          label={intl.formatMessage({
            id: 'pages.task.owner',
            defaultMessage: '主负责人',
          })}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={initialState?.currentUser?.userName}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode,
          }))}
        />

        {/* <ProFormSelect
          showSearch={true}
          mode={'multiple'}
          name="taskParticipants"
          width={390}
          initialValue={[initialState?.currentUser?.userName]}
          tooltip={'任务参与相关人员'}
          label={
            intl.formatMessage({
              id: 'pages.task.taskParticipants',
              defaultMessage: '参与人',
            })
          }
          rules={[
            {
              required: true,
            },
          ]}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode
          }))} /> */}
        <ProFormSelect
          showSearch={true}
          mode={'multiple'}
          name="labelCodes"
          width={390}
          tooltip={'该下拉列表展示的是“任务中心”标签。'}
          label={intl.formatMessage({
            id: 'pages.common.tag',
            defaultMessage: '标签',
          })}
          fieldProps={{
            dropdownRender: (menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Form style={{ height: 40 }} onFinish={addTag} ref={tagFormRef}>
                  <Space style={{ padding: '0 4px 4px' }}>
                    <Form.Item label={''} name={'labelNameCn'} rules={[{ required: true }]}>
                      <Input placeholder="中文名" />
                    </Form.Item>
                    <Form.Item label={''} name={'labelNameEn'} rules={[{ required: true }]}>
                      <Input placeholder="英文名" />
                    </Form.Item>
                    <Form.Item>
                      <Button type="text" size={'small'} htmlType="submit">
                        添加标签
                      </Button>
                    </Form.Item>
                  </Space>
                </Form>
              </>
            ),
          }}
          options={tagList?.map((item) => ({ label: item.labelName, value: item.labelCode }))}
        />
        <ProFormDateRangePicker
          name="time"
          width={390}
          label={intl.formatMessage({
            id: 'pages.task.form.time',
            defaultMessage: '开始-结束',
          })}
          fieldProps={{
            disabledDate: (current) => {
              return (
                disableDate[0] &&
                disableDate[1] &&
                (current.isBefore(disableDate[0], 'day') || current.isAfter(disableDate[1], 'day'))
              );
            },
          }}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                // console.log(value)
                if (!value || value.length !== 2 || !value[0] || !value[1]) {
                  return Promise.reject('时间不能为空');
                }

                // if (moment(value).endOf('day').isBefore(moment().startOf('day'))) {
                //   return Promise.reject('时间不能小于今天');
                // }
                if (value && value.length === 2) {
                  const [start, end] = value;
                  const dayDifference = moment(end).diff(moment(start), 'days');

                  // 检查是否超过3天
                  if (dayDifference > 2) {
                    return Promise.reject(new Error('时间跨度不能超过3天，请拆解'));
                  }

                  // // 检查是否跨周
                  // const startWeek = start.week();
                  // const endWeek = end.week();
                  // if (startWeek !== endWeek) {
                  //   return Promise.reject(new Error('所选日期范围不能跨周'));
                  // }
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <ProFormDigit
          name="estimatedPersonDay"
          tooltip={
            <div>
              参与总人数 * 工时（天）
              <br />
              成本预估=人/天*3000，可直观告知业务方预估花费成本，需对方考虑ROI是否合理。
              <br />
              以及后期如果成本过高，需要上升到不同维度进行决策。
            </div>
          }
          label={intl.formatMessage({
            id: 'pages.task.estimatedPersonDay',
            defaultMessage: '预估人天',
          })}
          width={390}
          required={true}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('预估人天不能为空');
                }
                if (value < 0) {
                  return Promise.reject('预估人天不能小于0');
                }
                if (value > 3) {
                  return Promise.reject('预估人天不能超过3天，请拆解');
                }
                return Promise.resolve();
              },
            },
          ]}
        />
      </ProFormGroup>

      {/* <a
        key="others"
        onClick={() => {
          if (showOthers) {
            setShowOthers(false)
          } else {
            setShowOthers(true)
          }
        }}
      >
        展开更多录入项 {showOthers ? <UpOutlined /> : <DownOutlined />}
      </a>
      {showOthers && <> */}
      <ProFormGroup>
        <ProFormSelect
          showSearch={true}
          colProps={{ md: 12, xl: 8 }}
          name="priorityLevel"
          width={390}
          initialValue={'3'}
          label={intl.formatMessage({
            id: 'pages.task.priorityLevel',
            defaultMessage: '优先级',
          })}
          // rules={[{ required: true, }]}
          options={props.priorityLevelList?.priority_level?.map((item: any) => ({
            label: item.dictLable,
            value: item.dictValue,
          }))}
        />
        <ProFormSelect
          showSearch={true}
          name="complexRate"
          width={390}
          tooltip={
            <div>
              <div>简单 - 小于5个工作日</div>
              <div>中等 - 5~10个工作日</div>
              <div>复杂 - 大于10个工作日</div>
            </div>
          }
          label={intl.formatMessage({
            id: 'pages.task.complexRate',
            defaultMessage: '复杂度',
          })}
          rules={[
            {
              // required: true,
            },
          ]}
          options={props.complexRateTypeList?.complex_rate_type?.map((item: any) => ({
            label: item.dictLable,
            value: item.dictValue,
          }))}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormTextArea
          name="remark"
          label={'备注'}
          width={390}
          fieldProps={{ autoSize: { minRows: 1, maxRows: 5 }, maxLength: 1000, showCount: true }}
        />
      </ProFormGroup>
      {taskRef?.current?.getFieldValue('issueIdList')?.length > 0 && (
        <ProFormList
          name="serviceParamList"
          creatorButtonProps={{
            creatorButtonText: '添加一行服务分支',
            type: 'primary',
          }}
          copyIconProps={false}
          deleteIconProps={false}
          // initialValue={[
          //   {
          //     serviceName: '',
          //     gitBranch: '',
          //     isExitBranch: false,
          //     isExitSelectBranch: false,
          //     isDropdownOpen: false
          //   },
          // ]}
          // actionRender={(field, action) =>
          //   [,
          //   ]}
        >
          {(meta, index, action, count) => {
            const row = action.getCurrentRowData();
            // console.log(row)
            return (
              <ProFormGroup key="group">
                <ProFormText
                  label={'序号'}
                  allowClear={false}
                  disabled
                  width={38}
                  fieldProps={{ value: `${index + 1}` }}
                />
                <ProFormSelect
                  showSearch={true}
                  name="serviceType"
                  width={100}
                  tooltip={'该任务相关联的服务的服务类型'}
                  label={intl.formatMessage({
                    id: 'pages.task.serviceType',
                    defaultMessage: '服务类型',
                  })}
                  onChange={(value) => {
                    const i = action.getCurrentRowData();
                    i.serviceName = '';
                    i.gitBranch = '';
                    i.createUserName = '';
                    action.setCurrentRowData(i);
                  }}
                  rules={[{ required: true }]}
                  options={serviceTypeList?.service_type?.map((item: any) => ({
                    label: item.dictLable,
                    value: item.dictValue,
                  }))}
                />

                <ProFormSelect
                  showSearch={true}
                  name="serviceName"
                  width={185}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  tooltip={'该任务相关联的服务'}
                  label={intl.formatMessage({
                    id: 'pages.task.service',
                    defaultMessage: '服务',
                  })}
                  onChange={async (value) => {
                    const i = action.getCurrentRowData();
                    // const param = {
                    //   issueIds: taskRef?.current?.getFieldValue('issueIdList'),
                    //   serviceParam: i,
                    // }
                    // const { resultObject } = await getTaskServiceByIssueIds(param);
                    // if (resultObject) {
                    //   i.createUserName = resultObject?.createUserName
                    //   i.gitBranch = resultObject?.gitBranch;
                    //   i.isExitBranch = true;
                    // } else {
                    i.isExitBranch = false;
                    i.gitBranch = '';
                    i.createUserName = '';
                    runGitList(value);
                    // }
                    // i.isDropdownOpen = false
                    action.setCurrentRowData(i);
                  }}
                  dependencies={['serviceType']}
                  request={async (params) => {
                    const { resultObject } = await listServiceByServiceType(params?.serviceType);
                    return resultObject?.map((item: any) => ({
                      label: item.serviceName,
                      value: item.serviceName,
                    }));
                  }}
                />
                <ProFormItem
                  label={'输入分支“+”创建或选择分支'}
                  name="gitBranch"
                  rules={[
                    // { required: true, message: '请输入或选择分支' },
                    {
                      pattern: /^[a-zA-Z0-9._/-]+$/,
                      message: '数字、字母、小数点、短横线、下划线',
                    },
                  ]}
                >
                  <AutoComplete
                    style={{ width: 205 }}
                    // open={action.getCurrentRowData()?.isDropdownOpen}
                    allowClear
                    // onSearch={handleSearch}
                    placeholder="输入要创建的分支名称或选择分支"
                    options={gitList?.map((item: any) => ({
                      label: item.branchName,
                      value: item.branchName,
                    }))}
                    filterOption={(inputValue, option) =>
                      option!.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    // disabled={action.getCurrentRowData()?.isExitBranch}
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        const i = action.getCurrentRowData();
                        runGitList(i?.serviceName);
                      }
                    }}
                    // onBlur={(s) => {
                    //   const i = action.getCurrentRowData();
                    //   i.isDropdownOpen = i.isDropdownOpen ? false : false
                    //   action.setCurrentRowData(i);
                    // }}
                    onChange={(value) => {
                      const branch = gitList?.filter(function (val) {
                        return value === val.branchName;
                      });
                      const i = action.getCurrentRowData();
                      if (!branch || branch?.length <= 0) {
                        i.isExitSelectBranch = false;
                      } else {
                        i.isExitSelectBranch = true;
                      }
                      i.createUserName = branch?.[0]?.createBy || '';
                      // i.isDropdownOpen = true
                      action.setCurrentRowData(i);
                    }}
                  />
                </ProFormItem>
                <ProFormItem label={'操作'}>
                  <>
                    <a
                      style={{ marginRight: 5 }}
                      hidden={row.isExitSelectBranch || !row?.gitBranch}
                      onClick={async () => {
                        if (!row || !row?.serviceName || !row?.gitBranch) {
                          message.error('服务类型、服务、分支不能为空！');
                          return;
                        }
                        const { status } = await createBranch(row);
                        if (status === 'success') {
                          row.isExitSelectBranch = true;
                          row.isDropdownOpen = false;
                          row.createUserName = initialState?.currentUser?.nickName;
                          action.setCurrentRowData(row);
                        }
                      }}
                    >
                      <Tooltip title="创建远程分支">
                        <PlusOutlined />
                      </Tooltip>
                    </a>
                    <a onClick={() => action.remove(index)}>
                      <Tooltip title="删除当前行">
                        <DeleteOutlined style={{ color: 'red', marginRight: 5 }} />
                      </Tooltip>
                    </a>
                    {/* <a hidden={row?.isExitBranch}
                        onClick={() => {
                          const list = taskRef?.current?.getFieldValue('serviceParamList')
                          const item = list[action.getCurrentRowData().name];
                          item.isDropdownOpen = item.isDropdownOpen ? false : true
                          taskRef?.current?.setFieldValue('serviceParamList', list)
                        }}>
                        <Tooltip title={taskRef?.current?.getFieldValue('serviceParamList')[action.getCurrentRowData().name]?.isDropdownOpen ? '关闭分支下拉框' : '展开分支下拉框'}><SelectOutlined style={{ color: '#3179F7' }} /></Tooltip></a> */}

                    <span hidden={!row?.createUserName}> Create By {row?.createUserName}</span>
                  </>
                </ProFormItem>
              </ProFormGroup>
            );
          }}
        </ProFormList>
      )}
      {/* </>} */}
      {/* <ProFormGroup>
        <ProFormUploadDragger width={700} name='uploadAttachments' label='上传附件' title={false} description={false}
          fieldProps={{
            customRequest: customRequest,
            fileList: fileList,
            beforeUpload: beforeUpload,
            onChange: onChange,
            onPreview: (file) => {
              setPreviewUrl(file.url || file.thumbUrl);
              setPreviewVisible(true);
            }
          }
          } />
      </ProFormGroup> */}
      <ProFormItem label={'描述'} name="description">
        <MyTinyMCEEditor
          data={state}
          readOnly={false}
          onEdit={() => {}}
          onChange={(editorState) => {
            taskRef?.current?.setFieldsValue({ description: editorState });
          }}
          height={300}
          extendControls={undefined}
        />
      </ProFormItem>
      {!props.isCreated && (
        <ProFormItem label={''} name="others">
          <TimeLineLog
            id={props.detail?.id}
            relationType={'RD_TASK'}
            commentReadOnly={true}
            showComment={false}
          />
        </ProFormItem>
      )}

      <Modal
        width={'80%'}
        open={previewVisible}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <iframe
          title="链接内容"
          src={previewUrl}
          style={{ width: '100%', height: document.body.offsetHeight - 200, border: 'none' }}
        />
      </Modal>
    </ModalForm>
  );
};

export default TaskModalForm;
