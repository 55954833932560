import { QuestionCircleOutlined } from '@ant-design/icons';
import { SelectLang as UmiSelectLang } from '@umijs/max';
import React, { useEffect, useState } from 'react';
import NoticeIconView from "../NoticeIcon";
import SystemSelect from "../SystemSelect";
import IssueSearch from '../IssueSearch';
import AddTaskView from '../AddTaskView';

export type SiderTheme = 'light' | 'dark';

export const SelectLang = () => {
  return (
    <UmiSelectLang
      style={{
        padding: 4,
      }}
    />
  );
};

export const NoticeIcon = () => {
  return (
    <NoticeIconView />
  );
};

export const SelectSystem = () => {

  return <SystemSelect />
};

export const issueSearch = () => {

  return <IssueSearch />
};

export const AddTask = () => {

  return <AddTaskView />
};



// export const Question = () => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         height: 26,
//       }}
//       onClick={() => {
//         window.open('https://pro.ant.design/docs/getting-started');
//       }}
//     >
//       <QuestionCircleOutlined />
//     </div>
//   );
// };
