import React, { useEffect, useState } from 'react';
import { Affix, Tooltip, Avatar } from 'antd';
import { useModel } from '@@/exports';
import request from 'umi-request';
import { QuestionOutlined } from '@ant-design/icons';

// 将这个函数移到组件外部，并确保它返回一个promise
async function findWxCodeByWxId(wxId) {
  return request('https://chat.imile-inc.com/wxwork/get-launch-code-wx?operatorWxId=' + wxId + '&singleWxId=WangRongGan-james');
}

const App = () => {
  const { initialState } = useModel('@@initialState');

  const openWxWork = async () => {
    try {
      const resp = await findWxCodeByWxId(initialState?.currentUser?.wxId);
      // 确保响应中有data属性
      if (resp?.data) {
        window.open("wxwork://launch?launch_code=" + resp.data);
      }
    } catch (error) {
      // 处理错误
      console.error('Error fetching WX code:', error);
    }
  };

  return (
    <Affix style={{
      position: 'fixed', bottom: 10, left: 10, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)', borderRadius: 20,
    }}>
      <Tooltip title={'有问题请请点击此处,开权限等'}>
        <Avatar style={{ opacity: 1, cursor: 'pointer' }} size="large" icon={<QuestionOutlined />} onClick={openWxWork} />
      </Tooltip>
    </Affix>
  );
};

export default App;