import {
  ModalForm,
  ProFormDateTimePicker,
  ProFormGroup,
  ProFormInstance,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormSwitch,
} from '@ant-design/pro-components';
import { useIntl } from '@umijs/max';

import React, { useEffect, useRef, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Form, Tooltip, message } from 'antd';

import 'braft-editor/dist/index.css';
import { listUsers, listChangeSystem, getDictData, uploadFile } from '@/services/common/api';
import { useModel, useRequest } from '@@/exports';
import moment from 'moment';

import { listIssueBasicInfo, listSelectIssue } from '@/services/rd/issues/api';
import { listPlanBySystemIds, listPlanByIds, getExecutors } from '@/services/rd/sprint/api';
import { listCaseBySystemIds, listCaseByIds } from '@/services/rd/test/api';

import MeetingRoomModal from '@/pages/RD/Review/components/MeetingRoomModal';

export type FormValueType = {
  reviewId?: string;
  reviewType?: string;
  systemId?: string;
  isFinish?: any;
  time?: string;
  frequency?: string;
  relationIds?: any;
  // issueIds?: any;
  // planIds?: any;
  // testCaseIds?: any;
} & Partial<API.RuleListItem>;

export type UpdateFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalOpen: boolean;
  // values: Partial<any>;
  isCreated: boolean;
  detail: any;
  relationIds: any;
  // isAllRead: number;
  // planName: string;
  isDisabled: boolean;
  systemIdList: any;
  reviewType: any;
  reviewName: any;
  /**区分sprint中的创建评审 */
  type?: string;
  /**评审参与人 */
  relationUsers?: string[];
};

const ReviewModalForm: React.FC<UpdateFormProps> = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const issueReviewRef = useRef<ProFormInstance>();
  const [defaultReviewsUsers, setDefaultReviewsUsers] = useState<[]>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [haveMeeting, setHaveMeeting] = useState<boolean>(true);
  const [hidenRelation, setHidenRelation] = useState<boolean>(false);
  const intl = useIntl();
  const { data: userList, run: runUserList } = useRequest(listUsers, { manual: true });
  const [formValues, setFormValues] = useState<FormValueType>({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [meetingRoomName, setMeetingRoomName] = useState('请点击选择会议室');
  const [meetingRoomId, setMeetingRoomId] = useState();
  const [handleRoomsOpen, setHandleRoomsOpen] = useState<boolean>(false);
  const [reviewStartTime, setReviewStartTime] = useState();
  const [reviewEndTime, setReviewEndTime] = useState();
  const [executorCodes, setExecutorCodes] = useState<[]>([]);

  const executorCodesRef = useRef([]);

  const issueOfReviewTypes = [
    'REQUIREMENT_REVIEW',
    'TECHNICAL_SCHEME_REVIEW',
    'DESIGN_DRAFT_REVIEW',
  ];
  const havaRelationReviewTypes = [
    'REQUIREMENT_REVIEW',
    'TECHNICAL_SCHEME_REVIEW',
    'DESIGN_DRAFT_REVIEW',
    'SPRINT_REVIEW',
    'TEST_CASE_REVIEW',
  ];

  const { data: selectIssueList, run: runSelectIssueList } = useRequest(
    (param) => {
      return listSelectIssue({
        ...param,
        // issueIds: props.detail?.reviewIssueIds,
        // progressList: ['SERVICE_CONFIRMED', 'REVIEWED'],
        // systemId: initialState?.defaultSystem?.systemDTO.systemId
      });
    },
    { manual: true },
  );

  // 查系统下拉列表
  const { data: systemList, run: runSystemList } = useRequest(() => listChangeSystem(), {
    manual: false,
  });

  // 查评审类型
  const { data: reviewTypeList, run: runReviewTypeList } = useRequest(
    () => {
      return getDictData('review_type');
    },
    { manual: false },
  );

  // 根据系统查规划下拉列表
  const { data: selectPlanList, run: runSelectPlanList } = useRequest(
    (param) => {
      return listPlanBySystemIds(param);
    },
    { manual: true },
  );

  // 根据系统查测试用例下拉列表
  const { data: selectTestCaseList, run: runSelectTestCaseList } = useRequest(
    (param) => {
      return listCaseBySystemIds(param);
    },
    { manual: true },
  );

  // 获取记录执行人
  const { run: runExecutorInfo } = useRequest(
    (relatedType: string, relatedIds: any) => {
      const body = {
        relatedType: relatedType,
        relatedIds: relatedIds,
      };
      return getExecutors(body);
    },
    {
      manual: true,
      onSuccess: (res) => {
        setExecutorCodes(res?.executorCodes);
        executorCodesRef.current = res?.executorCodes;
        issueReviewRef.current?.setFieldValue('inviteesCodes', res?.executorCodes);
      },
    },
  );

  const setFields = () => {
    if (props.detail?.isFinish) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    issueReviewRef.current?.setFieldsValue({
      reviewName: props.detail?.reviewName || '',
      reviewType: props.detail?.reviewType || '',
      reviewDate: props.detail?.reviewDate || null,
      // relationIds: props.detail?.relationIds || [],
      description: props.detail?.description || '',
      // summary: props.detail?.summary || '',
      inviteesCodes: props.detail?.inviteesCodes || [],
      isFinish: props.detail?.isFinish || false,
      systemIdList: props.detail?.systemIdList || [],
      uploadAttachments: props.detail?.uploadAttachments || [],
      meetingRoomId: props.detail?.meetingRoomId || null,
    });
    setFileList(props.detail?.uploadAttachments);

    if (issueOfReviewTypes.includes(props.detail?.reviewType)) {
      if (props.detail?.systemIdList?.length > 0) {
        runSelectIssueList({
          systemIdList: props.detail?.systemIdList,
          issueIds: props.detail?.relationIds,
        });
      }
      issueReviewRef.current?.setFieldValue('issueIds', props.detail?.relationIds);
    }

    if (props.detail?.reviewType === 'SPRINT_REVIEW') {
      if (props.detail?.systemIdList?.length > 0) {
        runSelectPlanList({
          systemIds: props.detail?.systemIdList,
          ids: props.detail?.relationIds,
        });
      }
      issueReviewRef.current?.setFieldValue('planIds', props.detail?.relationIds);
    }

    if (props.detail?.reviewType === 'TEST_CASE_REVIEW') {
      if (props.detail?.systemIdList?.length > 0) {
        runSelectTestCaseList({
          systemIds: props.detail?.systemIdList,
          ids: props.detail?.relationIds,
        });
      }
      issueReviewRef.current?.setFieldValue('testCaseIds', props.detail?.relationIds);
    }
  };

  // 系统变更时，刷新各个下拉数据
  const changeSystem = async () => {
    // 获取系统id集合
    const systemIds =
      issueReviewRef?.current?.getFieldValue('systemIdList')?.length > 0
        ? issueReviewRef?.current?.getFieldValue('systemIdList')
        : ['null'];

    // 获取评审类型
    const reviewType = issueReviewRef?.current?.getFieldValue('reviewType');

    const issueIds =
      reviewType === issueOfReviewTypes.includes(reviewType)
        ? issueReviewRef.current?.getFieldValue('issueIds')
        : [];
    const planIds =
      reviewType === 'SPRINT_REVIEW' ? issueReviewRef.current?.getFieldValue('planIds') : [];
    const testCaseIds =
      reviewType === 'TEST_CASE_REVIEW' ? issueReviewRef.current?.getFieldValue('testCaseIds') : [];

    // 根据系统id过滤选择的需求
    if (issueIds?.length > 0) {
      // 查选中需求的基础信息
      const selectIssueParam = {
        issueIdList: issueIds,
      };
      const { resultObject } = await listIssueBasicInfo(selectIssueParam);

      // 用系统过滤下拉框选中的需求并赋值
      const validIssueIds = resultObject
        ?.filter((u) => systemIds.includes(u.systemId))
        ?.map((i) => i.id);
      issueReviewRef.current?.setFieldValue('issueIds', validIssueIds);
    }

    // 根据系统id过滤选择的规划
    if (planIds?.length > 0) {
      // 查选中规划的基础信息
      const selectParam = {
        ids: planIds,
        systemIds: systemIds,
      };
      const { resultObject } = await listPlanByIds(selectParam);

      // 用系统过滤下拉框选中的规划并赋值
      const validIds = resultObject?.map((i) => i.id);
      issueReviewRef.current?.setFieldValue('planIds', validIds);
    }

    // 根据系统id过滤选择的用例
    if (testCaseIds?.length > 0) {
      // 查选中用例的基础信息
      const selectParam = {
        ids: testCaseIds,
      };
      const { resultObject } = await listCaseByIds(selectParam);

      // 用系统过滤下拉框选中的用例并赋值
      const validIds = resultObject
        ?.filter((u) => systemIds.includes(u.systemId))
        ?.map((i) => i.id);
      issueReviewRef.current?.setFieldValue('testCaseIds', validIds);
    }

    // 刷新需求下拉列表数据
    if (issueOfReviewTypes.includes(reviewType)) {
      runSelectIssueList({
        systemIdList: systemIds,
        issueIds: props?.isCreated ? null : issueReviewRef.current?.getFieldValue('issueIds'),
      });
    }

    // 刷新规划下拉列表数据
    if (reviewType === 'SPRINT_REVIEW') {
      runSelectPlanList({
        systemIds: systemIds,
        ids: props?.isCreated ? null : issueReviewRef.current?.getFieldValue('planIds'),
      });
    }

    // 刷新用例下拉列表数据
    if (reviewType === 'TEST_CASE_REVIEW') {
      runSelectTestCaseList({
        systemIds: systemIds,
        ids: props?.isCreated ? null : issueReviewRef.current?.getFieldValue('testCaseIds'),
      });
    }
  };

  // 系统变更时，刷新各个下拉数据
  const changeReviewType = async () => {
    setHidenRelation(false);
    executorCodesRef.current = [];

    // 获取评审类型
    const reviewType = issueReviewRef?.current?.getFieldValue('reviewType');

    // 获取系统id集合
    const systemIds = issueReviewRef?.current?.getFieldValue('systemIdList');

    // 刷新需求下拉列表数据
    if (issueOfReviewTypes.includes(reviewType)) {
      if (systemIds?.length > 0) {
        runSelectIssueList({
          systemIdList: systemIds,
        });
      }
    }

    // 刷新规划下拉列表数据
    if (reviewType === 'SPRINT_REVIEW') {
      if (systemIds?.length > 0) {
        runSelectPlanList({
          systemIds: systemIds,
        });
      }
    }

    // 刷新用例下拉列表数据
    if (reviewType === 'TEST_CASE_REVIEW') {
      if (systemIds?.length > 0) {
        runSelectTestCaseList({
          systemIds: systemIds,
        });
      }
    }

    if (!issueOfReviewTypes.includes(reviewType)) {
      issueReviewRef.current?.setFieldValue('issueIds', []);
    }
    if (reviewType !== 'SPRINT_REVIEW') {
      issueReviewRef.current?.setFieldValue('planIds', []);
    }
    if (reviewType !== 'TEST_CASE_REVIEW') {
      issueReviewRef.current?.setFieldValue('testCaseIds', []);
    }

    if (!havaRelationReviewTypes.includes(reviewType)) {
      setHidenRelation(true);
    }
  };

  // const onChange: UploadProps['onChange'] = async ({ file, fileList: newFileList }) => {
  //   if (!file.status) {
  //     newFileList.splice(fileList.indexOf(file), 1);
  //   }
  //   if (file.status === 'done') {
  //     let obj = {
  //       uid: newFileList[newFileList.length - 1].uid,
  //       name: file.response.fileName,
  //       status: newFileList[newFileList.length - 1].status,
  //       url: file.response.fileUrl,
  //       ...file.response
  //     }
  //     newFileList[newFileList.length - 1] = obj;
  //   } else if (file.status === 'error') {
  //     message.error(`${file.name} 文件上传失败`);
  //   }
  //   setFileList(newFileList);
  // };
  // const customRequest: UploadProps['customRequest'] = async ({ file, onSuccess, onError }) => {
  //   const fd = new FormData()
  //   fd.append('file', file)
  //   await uploadFile(fd).then(resp => {
  //     // 上传成功时，调用 onSuccess 并传递服务器返回的文件 URL
  //     onSuccess(resp?.resultObject);
  //   }).catch(error => {
  //     // 上传失败时，调用 onError 并传递错误信息
  //     onError(error);
  //   });

  // };

  // const beforeUpload = (file: RcFile, fileList) => {
  //   const isLt2M = file.size / 1024 / 1024 < 5;
  //   if (!isLt2M) {
  //     // console.log(fileList.indexOf(file))
  //     message.error('上传文件不能超过5MB');
  //     return false;
  //   }
  //   return true;
  // };

  useEffect(() => {
    if (props.updateModalOpen) {
      runUserList();
      if (props.isCreated) {
        setMeetingRoomName('请点击选择会议室');
        if (initialState?.defaultSystem?.systemDTO?.systemId !== 'all') {
          runSelectIssueList({
            name: '',
            issueIds: props.relationIds,
            systemIdList: [initialState?.defaultSystem?.systemDTO?.systemId],
          });

          // let users: any = [];
          // const developInfo = initialState?.defaultSystem?.systemDTO?.developInfo || [];
          // const frontWebInfo = initialState?.defaultSystem?.systemDTO?.frontWebInfo || [];
          // const productManagerInfo = initialState?.defaultSystem?.systemDTO?.productManagerInfo || [];
          // const testEngineerInfo = initialState?.defaultSystem?.systemDTO?.testEngineerInfo || [];
          // const uiDesignInfo = initialState?.defaultSystem?.systemDTO?.uiDesignInfo || [];
          // users = users.concat(developInfo, frontWebInfo, productManagerInfo, testEngineerInfo, uiDesignInfo);
          // users = [...new Set(users)]
          // setDefaultReviewsUsers(users);
        } else {
          runSelectIssueList({
            name: '',
            issueIds: props.relationIds,
            systemIdList: props?.systemIdList?.length > 0 ? props?.systemIdList : [null],
          });
        }

        if (props?.relationIds.length > 0) {
          if (issueOfReviewTypes.includes(props?.reviewType)) {
            if (props.detail?.systemIdList?.length > 0) {
              runSelectIssueList({
                systemIdList:
                  initialState?.defaultSystem?.systemDTO?.systemId === 'all'
                    ? []
                    : [initialState?.defaultSystem?.systemDTO?.systemId],
                issueIds: props?.relationIds,
              });
            }
            runExecutorInfo('ISSUE', props?.relationIds);

            issueReviewRef.current?.setFieldValue('reviewName', props?.reviewName);
            issueReviewRef.current?.setFieldValue('issueIds', props?.relationIds);
          }
          if (props?.reviewType === 'SPRINT_REVIEW') {
            if (props.detail?.systemIdList?.length > 0) {
              runSelectPlanList({
                systemIds:
                  initialState?.defaultSystem?.systemDTO?.systemId === 'all'
                    ? []
                    : [initialState?.defaultSystem?.systemDTO?.systemId],
                ids: props?.relationIds,
              });
            }
            runExecutorInfo('PLAN', props?.relationIds);

            issueReviewRef.current?.setFieldValue('planIds', props?.relationIds);
          }
          if (props?.reviewType === 'TEST_CASE_REVIEW') {
            if (props.detail?.systemIdList?.length > 0) {
              runSelectTestCaseList({
                systemIds:
                  initialState?.defaultSystem?.systemDTO?.systemId === 'all'
                    ? []
                    : [initialState?.defaultSystem?.systemDTO?.systemId],
                ids: props?.relationIds,
              });
            }

            issueReviewRef.current?.setFieldValue('testCaseIds', props?.relationIds);
          }
        }
      }

      if (!props.isCreated) {
        setMeetingRoomName(props?.detail?.meetingRoom || '请点击选择会议室');
        setFields();
      }
    }
  }, [props.updateModalOpen, props.detail]);

  return (
    <>
      <ModalForm
        formRef={issueReviewRef}
        title={
          props.isCreated
            ? intl.formatMessage({
                id: 'pages.common.create',
                defaultMessage: '创建',
              })
            : intl.formatMessage({
                id: 'pages.common.edit',
                defaultMessage: '编辑',
              })
        }
        width="800px"
        open={props.updateModalOpen}
        onValuesChange={(changedValues, allValues) => {
          // 在这里可以调用其他方法，做你想要的操作
          // console.log('表单值发生变化', changedValues, allValues);
          // 调用其他方法...
          if (changedValues.summary) {
            setChecked(true);
          }
        }}
        onFinish={async (value) => {
          if (issueOfReviewTypes.includes(value?.reviewType)) {
            value.relationIds = value?.issueIds;
          }
          if (value?.reviewType === 'SPRINT_REVIEW') {
            value.relationIds = value?.planIds;
          }
          if (value?.reviewType === 'TEST_CASE_REVIEW') {
            value.relationIds = value?.testCaseIds;
          }
          await props.onSubmit(value);
        }}
        onOpenChange={(open) => {
          !open && props.onCancel();
        }}
        modalProps={{ destroyOnClose: true }}
      >
        <ProFormText
          name="reviewName"
          label={
            props?.type === 'sprint'
              ? intl.formatMessage({
                  id: 'pages.issueReview.allName',
                  defaultMessage: '名称',
                })
              : intl.formatMessage({
                  id: 'pages.issueReview.name',
                  defaultMessage: '评审名称',
                })
          }
          width="750px"
          initialValue={props?.reviewName}
          rules={[
            {
              required: true,
            },
          ]}
        />

        <ProFormGroup>
          <ProFormSelect
            showSearch={true}
            name="reviewType"
            disabled={props?.type === 'sprint' ? false : props?.isDisabled}
            width="180px"
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                    id: 'pages.task.type',
                    defaultMessage: '类型',
                  })
                : intl.formatMessage({
                    id: 'pages.issueReview.reviewType',
                    defaultMessage: '评审类型',
                  })
            }
            rules={[
              {
                required: true,
                message: props?.type === 'sprint' ? '请选择类型' : '请选择评审类型',
              },
            ]}
            onChange={(...row) => changeReviewType(row)}
            initialValue={props?.reviewType}
            options={reviewTypeList?.review_type?.map((item: any) => ({
              label: item.dictLable,
              value: item.dictValue,
            }))}
          />

          <ProFormSelect
            mode={'multiple'}
            showSearch={true}
            name="systemIdList"
            disabled={props?.type === 'sprint' ? true : props?.isDisabled}
            width="538px"
            label={intl.formatMessage({
              id: 'pages.system.title',
              defaultMessage: '系统',
            })}
            rules={[
              {
                required: true,
                message: '请选择系统',
              },
            ]}
            onChange={(...row) => changeSystem(row)}
            initialValue={props?.systemIdList}
            options={systemList
              ?.filter((u) => u.systemId !== 'all')
              ?.map((item) => ({ label: item.systemName, value: item.systemId, ...item }))}
          />

          <ProFormSelect
            disabled={props?.isDisabled}
            hidden={
              !issueOfReviewTypes.includes(issueReviewRef?.current?.getFieldValue('reviewType')) ||
              hidenRelation ||
              props?.type === 'sprint'
            }
            mode={'multiple'}
            showSearch={true}
            name="issueIds"
            width="750px"
            tooltip={'评审需求只能未关闭的需求'}
            label={intl.formatMessage({
              id: 'review.issue',
              defaultMessage: '评审需求',
            })}
            onChange={(...id) => {
              if (!issueReviewRef?.current?.getFieldValue('reviewName')) {
                issueReviewRef?.current?.setFieldValue('reviewName', id[1][0]?.title);
              }

              runExecutorInfo('ISSUE', id[0]);
            }}
            initialValue={props?.relationIds}
            options={selectIssueList?.results?.map((item: any) => ({
              label: item.issueName,
              value: item.id,
            }))}
            fieldProps={{
              onSearch: (name) => {
                runSelectIssueList({
                  name: name,
                  systemIdList:
                    issueReviewRef?.current?.getFieldValue('systemIdList')?.length > 0
                      ? issueReviewRef?.current?.getFieldValue('systemIdList')
                      : ['null'],
                });
              },
            }}
          />

          <ProFormSelect
            disabled={props.isDisabled}
            hidden={
              issueReviewRef?.current?.getFieldValue('reviewType') !== 'SPRINT_REVIEW' ||
              hidenRelation
            }
            mode={'multiple'}
            showSearch={true}
            name="planIds"
            width="750px"
            tooltip={'评审Sprint只能是进行中的Sprint'}
            label={intl.formatMessage({
              id: 'review.sprint',
              defaultMessage: '评审Sprint',
            })}
            onChange={(...id) => {
              issueReviewRef.current?.setFieldValue('reviewName', id[1][0]?.title);
              runExecutorInfo('PLAN', id[0]);
            }}
            initialValue={props?.relationIds}
            options={selectPlanList?.map((item: any) => ({
              label: item.planName,
              value: item.id,
            }))}
            fieldProps={{
              onSearch: (name) => {
                runSelectPlanList({
                  name: name,
                  systemIds:
                    issueReviewRef.current?.getFieldValue('systemIdList')?.length > 0
                      ? issueReviewRef.current?.getFieldValue('systemIdList')
                      : ['null'],
                });
              },
            }}
          />

          <ProFormSelect
            disabled={props.isDisabled}
            hidden={
              issueReviewRef.current?.getFieldValue('reviewType') !== 'TEST_CASE_REVIEW' ||
              hidenRelation
            }
            mode={'multiple'}
            showSearch={true}
            name="testCaseIds"
            width="750px"
            tooltip={'评审测试用例只能是未完成评审的用例'}
            label={intl.formatMessage({
              id: 'review.test.case',
              defaultMessage: '评审测试用例',
            })}
            onChange={(...id) => {
              issueReviewRef.current?.setFieldValue('reviewName', id[1][0]?.title);
            }}
            initialValue={props.relationIds}
            options={selectTestCaseList?.map((item: any) => ({
              label: item.caseName,
              value: item.id,
            }))}
            fieldProps={{
              onSearch: (name) => {
                runSelectTestCaseList({
                  name: name,
                  systemIds:
                    issueReviewRef.current?.getFieldValue('systemIdList')?.length > 0
                      ? issueReviewRef.current?.getFieldValue('systemIdList')
                      : ['null'],
                });
              },
            }}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormDateTimePicker
            name="reviewDate"
            width="180px"
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                    id: 'pages.issuePool.allReviewDate',
                    defaultMessage: '时间',
                  })
                : intl.formatMessage({
                    id: 'pages.issuePool.reviewDate',
                    defaultMessage: '评审时间',
                  })
            }
            rules={[
              {
                required: checked ? false : true,
                validator: (_, value) => {
                  if (moment(value).isBefore(moment()) && !checked) {
                    return Promise.reject('时间不能小于当前时间');
                  }
                  return Promise.resolve();
                },
              },
            ]}
            fieldProps={{
              disabledDate: (current) => {
                return current && current < moment().startOf('days');
              },
            }}
          />

          <ProFormSelect
            showSearch={true}
            mode={'multiple'}
            name="inviteesCodes"
            width="538px"
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                    id: 'pages.task.taskParticipants',
                    defaultMessage: '参与人',
                  })
                : intl.formatMessage({
                    id: 'pages.issueReview.reviewUsers',
                    defaultMessage: '评审参与人',
                  })
            }
            initialValue={props?.type === 'sprint' ? props?.relationUsers : null}
            options={userList?.map((item: any) => ({
              label: item.userName,
              value: item.userCode,
            }))}
            value={executorCodesRef.current}
            rules={[{ required: true }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormSwitch
            checkedChildren="预约"
            unCheckedChildren="不预约"
            name="haveMeeting"
            tooltip={'如果选择预约，在提交表单时会直接预约一个会议'}
            label={intl.formatMessage({
              id: 'whether.to.schedule.a.meeting',
              defaultMessage: '是否预约会议',
            })}
            initialValue={haveMeeting}
            onChange={(val) => {
              setHaveMeeting(val);
            }}
          />

          <ProFormRadio.Group
            name="meetingDuration"
            // width="200px"
            hidden={!haveMeeting}
            label={intl.formatMessage({
              id: 'pages.issueReview.form.meetingTime',
              defaultMessage: '会议时长',
            })}
            initialValue={'1800'}
            width="md"
            rules={[
              {
                required: true,
              },
            ]}
            options={[
              {
                label: '30分钟',
                value: '1800',
              },
              {
                label: '一个小时',
                value: '3600',
              },
            ]}
          />

          <Form.Item label="会议室" name="meetingRoomId">
            <Tooltip title={meetingRoomName}>
              <Button
                style={{
                  width: '230px',
                  float: 'left',
                  backgroundColor: 'rgba(112, 238, 95, 0.45)',
                }}
                onClick={() => {
                  if (!issueReviewRef?.current?.getFieldValue('reviewDate')) {
                    if (props?.type === 'sprint') {
                      message.warning('请先选择时间！');
                      return;
                    }
                    message.warning('请先选择评审时间！');
                    return;
                  }

                  const startStamp = moment(
                    issueReviewRef?.current?.getFieldValue('reviewDate'),
                  ).unix();
                  const endStamp =
                    parseInt(startStamp) +
                    parseInt(issueReviewRef?.current?.getFieldValue('meetingDuration'));
                  setReviewStartTime(startStamp);
                  setReviewEndTime(endStamp);
                  setHandleRoomsOpen(true);
                }}
              >
                {meetingRoomName.length > 20
                  ? meetingRoomName.substring(0, 20) + '...'
                  : meetingRoomName}
              </Button>
            </Tooltip>
          </Form.Item>

          <ProFormSelect
            showSearch={true}
            mode={'multiple'}
            name="remindBefores"
            width="162px"
            hidden={!haveMeeting}
            label={intl.formatMessage({
              id: 'pages.issueReview.meetingRemindBefores',
              defaultMessage: '开始前提醒',
            })}
            options={[
              {
                label: '会议开始时提醒',
                value: 0,
              },
              {
                label: '5分钟前提醒',
                value: 300,
              },
              {
                label: '15分钟前提醒',
                value: 900,
              },
              {
                label: '一小时前提醒',
                value: 3600,
              },
              {
                label: '一天前提醒',
                value: 86400,
              },
            ]}
            initialValue={[900]}
            rules={[{ required: true }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormTextArea
            width={750}
            placeholder="请输入内容"
            name="description"
            fieldProps={{ autoSize: { minRows: 3, maxRows: 20 } }}
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                    id: 'description',
                    defaultMessage: '描述',
                  })
                : intl.formatMessage({
                    id: 'pages.issueReview.reviewDesc',
                    defaultMessage: '评审描述',
                  })
            }
          />
        </ProFormGroup>
      </ModalForm>

      <MeetingRoomModal
        key={Math.random()}
        reviewStartTime={reviewStartTime}
        reviewEndTime={reviewEndTime}
        onCancel={() => {
          setHandleRoomsOpen(false);
        }}
        onSubmit={async (value) => {
          issueReviewRef.current?.setFieldValue('meetingRoomId', value.roomId);
          setMeetingRoomName(value.roomName);
          setHandleRoomsOpen(false);
        }}
        isModalOpen={handleRoomsOpen}
      />
    </>
  );
};

export default ReviewModalForm;
