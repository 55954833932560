
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { selectIndustryList } from '@/services/settings/industry/api';
import { useRequest } from '@@/exports';

export type CustomCascaderSelectProps = {
    onChange?: (values: string | string[]) => void;
    multiple?: boolean;
    type?: 'code' | 'id';
    value?: string | string[];
    selecrtLevel?: number;
    width?: number | string;
};

const NumberIcon = ({ number, color }) => (
    <span
        style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '13px',
            height: '13px',
            borderRadius: '50%',
            backgroundColor: 'white',
            color: color,
            fontSize: '12px',
            marginRight: '3px',
            border: `1px solid ${color}`,
        }}
    >
        {number}
    </span>
);

const AppComponent: React.FC<CustomCascaderSelectProps> = (props) => {
    const { onChange, multiple = true, type = 'code', value, selecrtLevel = 100, width = '100%' } = props;
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    const { data: industryList, run: runIndustryList } = useRequest((industryName: string = '') => {
        return selectIndustryList({ industryName })
    }, { manual: false });

    const getLevelColor = (level: number) => {
        const colors = ['#1890ff', '#faad14', '#f5222d', '#722ed1'];
        return colors[level % colors.length];
    };

    const cascaderOptions = useMemo(() => {
        const convertToCascaderOption = (item: any, parentPath: string = '', level: number = 0): any => {
            const currentPath = parentPath ? `${parentPath} / ${item.industryName}` : item.industryName;
            const color = getLevelColor(level);
            return {
                value: type === 'code' ? item.industryCode : item.id,
                title: (
                    <span>
                        <NumberIcon number={level + 1} color={color} />
                        {item.industryName}
                    </span>
                ),
                key: item.id,
                fullTitle: currentPath,
                disabled: item.status !== 'ACTIVE',
                children: item.level < selecrtLevel ? item.children?.map(child => convertToCascaderOption(child, currentPath, level + 1)) : []
            };
        };

        return industryList?.map(item => convertToCascaderOption(item)) || [];
    }, [industryList, type, selecrtLevel]);

    useEffect(() => {
        if (value) {
            const convertToLabelInValue = (val: string | string[]) => {
                if (Array.isArray(val)) {
                    return val.map(v => ({ value: v, label: findNodeByValue(cascaderOptions, v)?.fullTitle || v }));
                }
                return { value: val, label: findNodeByValue(cascaderOptions, val)?.fullTitle || val };
            };
            setSelectedOptions(convertToLabelInValue(value));
        } else {
            setSelectedOptions([]);
        }
        setFilteredOptions(cascaderOptions);
    }, [value, cascaderOptions]);

    const findNodeByValue = useCallback((nodes: any[], targetValue: string): any | null => {
        for (const node of nodes) {
            if (node.value === targetValue) {
                return node;
            }
            if (node.children) {
                const found = findNodeByValue(node.children, targetValue);
                if (found) return found;
            }
        }
        return null;
    }, []);

    const handleChange = (newValue: any, labelList: any) => {
        // console.log('onChange ', newValue, labelList);
        setSelectedOptions(newValue);
        if (onChange) {
            if (multiple) {
                onChange(newValue.map((v: any) => v.value));
            } else {
                onChange(newValue.value);
            }
        }
    };

    const filterTreeData = useCallback((inputValue: string, node: any) => {
        return node.fullTitle.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    }, []);

    const handleSearch = useCallback((inputValue: string) => {
        setSearchValue(inputValue);
        if (!inputValue) {
            setFilteredOptions(cascaderOptions);
            return cascaderOptions.map(node => node.key);
        }

        const filterNodes = (nodes: any[]): any[] => {
            return nodes.reduce((acc, node) => {
                const nodeMatches = filterTreeData(inputValue, node);
                const childrenMatch = node.children && filterNodes(node.children);

                if (nodeMatches || (childrenMatch && childrenMatch.length > 0)) {
                    acc.push({
                        ...node,
                        children: childrenMatch
                    });
                }
                return acc;
            }, []);
        };

        const filtered = filterNodes(cascaderOptions);
        setFilteredOptions(filtered);

        return filtered.map(node => node.key);
    }, [cascaderOptions, filterTreeData]);

    return (
        <TreeSelect
            showSearch
            allowClear
            treeData={filteredOptions}
            value={selectedOptions}
            onChange={handleChange}
            treeCheckable={multiple}
            treeCheckStrictly={multiple}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            onSearch={handleSearch}
            filterTreeNode={filterTreeData}
            style={{ width }}
            treeNodeFilterProp="fullTitle"
            labelInValue
            multiple={multiple}
            treeLine={true}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
    );
};

export default AppComponent;
