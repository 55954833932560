import { useEffect, useState } from 'react';
import { Tag, message } from 'antd';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useModel, useRequest } from 'umi';
import { getNoticesNotReadCount, pagedNoticeMsg, readAll, readById } from '@/services/common/api';
import { history } from '@umijs/max';

import NoticeIcon from './NoticeIcon';
import useWebsocket from "@/components/WebSocket";
import { roles } from "aria-query";

export type GlobalHeaderRightProps = {
  fetchingNotices?: boolean;
  onNoticeVisibleChange?: (visible: boolean) => void;
  onNoticeClear?: (tabName?: string) => void;
};


const getNoticeData = (notices: API.NoticeIconItem[]): API.NoticeIconItem[] => {
  if (!notices || notices.length === 0 || !Array.isArray(notices)) {
    return [];
  }

  const newNotices = notices?.map((notice) => {
    const newNotice = { ...notice };

    if (newNotice.createDate) {
      newNotice.createDate = moment(notice.createDate as string).fromNow();
    }

    if (newNotice.id) {
      newNotice.key = newNotice.id;
    }

    if (newNotice.relationType) {
      const type = {
        ISSUE: '需求',
        TASK: '任务',
        SPRINT: '规划',
        REVIEW: '评审',
        BUG: '缺陷',
        BUSINESS_ISSUE: '业务需求'
      }[newNotice.relationType];
      const color = {
        ISSUE: 'gold',
        TASK: 'blue',
        SPRINT: 'green',
        REVIEW: 'cyan',
        BUG: 'red',
        BUSINESS_ISSUE: 'purple'
      }[newNotice.relationType];
      newNotice.extra = (
        <Tag
          color={color}
          style={{
            marginRight: 0,
          }}
        >
          {type}
        </Tag>
      ) as any;
    }

    return newNotice;
  });
  return newNotices;
};

const getUnreadData = (noticeData: Record<string, API.NoticeIconItem[]>) => {
  const unreadMsg: Record<string, number> = {};
  Object.keys(noticeData).forEach((key) => {
    const value = noticeData[key];

    if (!unreadMsg[key]) {
      unreadMsg[key] = 0;
    }

    if (Array.isArray(value)) {
      unreadMsg[key] = value.filter((item) => !item.read).length;
    }
  });
  return unreadMsg;
};


function updateDataAction(data) {
  return {
    type: 'UPDATE_DATA',
    payload: data
  };
}


const NoticeIconView: React.FC = () => {
  const [sysData, setSysData] = useState<API.NoticeIconItem[]>([]);
  const [msgData, setMsgData] = useState<API.NoticeIconItem[]>([]);
  const [sysUnReadCnt, setSysUnReadCnt] = useState<number>(0);
  const [msgUnReadCnt, setMsgUnReadCnt] = useState<number>(0);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [noticesCount, setNoticesCount] = useState<any>(0);
  // const { data } = useRequest(() => {
  //   pagedNoticeMsg
  // }, { ready: false, manual: false });
  const { data: count, run: runGetCount } = useRequest(getNoticesNotReadCount, { onSuccess: (resp) => { setNoticesCount(resp) } });
  const { wsData, readyState, reconnect, sendMessage } = useWebsocket();



  const getData = async () => {
    const param = { current: 1, showCount: 20, type: 'SYS', selectType: 'card' }
    const resp = await pagedNoticeMsg(param);
    const sys = getNoticeData(resp?.resultObject?.results)
    setSysData(sys)
    setSysUnReadCnt(resp?.pagination?.totalResult)
    const param1 = { current: 1, showCount: 20, type: 'MSG', selectType: 'card' }
    const resp1 = await pagedNoticeMsg(param1);
    const msg = getNoticeData(resp1?.resultObject?.results)
    setMsgData(msg)
    setMsgUnReadCnt(resp1?.pagination?.totalResult)
  }



  useEffect(() => {
    // 如果是已关闭且是当前页面自动重连
    if (readyState.key === 3) {
      reconnect();
    }
  }, []);


  useEffect(() => {
    if (readyState.key === 1) {
      console.log(readyState, wsData, '----------readyState');
      // 接受到socket数据， 进行业务逻辑处理
      if (wsData && wsData.indexOf('连接成功') < 0) {
        setNoticesCount(wsData);
        // 通知其他页面数据已更新
        window.dispatchEvent(new Event('noticeDataUpdated'));
        if (popupVisible) {
          getData();
        }
      }
    }
  }, [wsData]);

  // const noticeData = getNoticeData(notices);
  // const unreadMsg = getUnreadData(noticeData || {});

  const changeReadState = async (id: string) => {
    const { status } = await readById(id);
    if (status === 'success') {
      getData();
      message.success('已读完成');
    }
  };

  const clearReadState = async (title: string, key: string) => {
    const { status } = await readAll();
    if (status === 'success') {
      getData();
      message.success('全部已读');
    }
  };

  return (
    <NoticeIcon
      count={noticesCount}
      onItemClick={(item) => {
        changeReadState(item.id!);
      }}
      popupVisible={popupVisible}
      onPopupVisibleChange={(e) => { if (e) { getData(); }; setPopupVisible(e); }}
      onClear={(title: string, key: string) => clearReadState(title, key)}
      loading={false}
      clearText="全部已读"
      viewMoreText="查看更多"
      onViewMore={() => history.push('/noticeIcon')}
      clearClose
    >
      <NoticeIcon.Tab
        tabKey="notification"
        count={sysUnReadCnt}
        list={sysData}
        title="系统消息"
        emptyText="你已查看所有通知"
        showViewMore={true}
        showClear={true}
      />
      <NoticeIcon.Tab
        tabKey="message"
        count={msgUnReadCnt}
        list={msgData}
        title="消息"
        emptyText="您已读完所有消息"
        showViewMore
      />
      {/* <NoticeIcon.Tab
        tabKey="event"
        title="待办"
        emptyText="你已完成所有待办"
        count={unreadMsg.event}
        list={noticeData.event}
        showViewMore
      /> */}
    </NoticeIcon>
  );
};

export default NoticeIconView;


