// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';




/** 删除Sprint */
export async function delSprint(planId: string) {
  return request<{}>('/issue-plan/delete?planId=' + planId, {
    method: 'GET',
  });
}
/** 恢复Sprint */
export async function restoreSprint(planId: string) {
  return request<{}>('/issue-plan/restore?planId=' + planId, {
    method: 'GET',
  });
}

/** 归档Sprint */
export async function closeSprint(planId: string) {
  return request<{}>('/issue-plan/close?planId=' + planId, {
    method: 'GET',
  });
}

/** 获取详情 */
export async function getSprintDetail(planId: string,
  options?: { [key: string]: any },
) {
  return request<API.SprintListItem>('/issue-plan/detail?planId=' + planId, {
    method: 'GET',
    ...(options || {}),
  });
}



/** 获取甘特图详情 */
export async function listSprintGanttTree(planId: string,
  options?: { [key: string]: any },
) {
  return request<API.SprintListItem>('/issue-plan/list-issue-tree?planId=' + planId, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 创建或修改Sprint */
export async function createOrUpdSprint(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.SystemList>('/issue-plan/create-or-update-plan', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/** 创建迭代版本号 */
export async function iterationSprint(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.SystemList>('/issue-plan/iteration-plan', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 移除需求 */
export async function removePlanIssue(
  body: {},
  options?: { [key: string]: any },
) {
  return request('/issue-plan/remove-plan-issue', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}




/** Sprint下拉列表 */
export async function listSprints() {
  return request<API.SelectSprint>('/issue-plan/list-plan', {
    method: 'GET',
  });
}


/** Sprint需求列表 */
export async function listSprintIssues(planId: string) {
  return request<API.IssueListItem[]>('/issue-plan/issue-detail?planId=' + planId, {
    method: 'GET',
  });
}


export async function getPlanWorkHoursPie(planId: string) {
  return request<API.ImileResponse<any>>('/issue-plan-chart/get-plan-work-hours-pie?planId=' + planId, {
    method: 'GET',
  });
}

export async function getPlanHoursRank(planId: string) {
  return request<API.ImileResponse<any>>('/issue-plan-chart/get-plan-work-hours-rank?planId=' + planId, {
    method: 'GET',
  });
}
export async function getPlanBugRank(planId: string) {
  return request<API.ImileResponse<any>>('/issue-plan-chart/get-plan-bug-rank?planId=' + planId, {
    method: 'GET',
  });
}
export async function getPlanBar(planId: string, type: string) {
  return request<API.ImileResponse<any>>('/code-quality/get-plan-bar', {
    method: 'GET',
    params: {
      planId,
      type
    }
  });
}
export async function getIssueBar(issueId: string, type: string) {
  return request<API.ImileResponse<any>>('/code-quality/get-issue-bar', {
    method: 'GET',
    params: {
      issueId,
      type
    }
  });
}

export async function getStatusCountBurnoutChart(planId: string) {
  return request<API.ImileResponse<any>>('/issue-plan-chart/get-plan-issue-burndown-chart?planId=' + planId, {
    method: 'GET',
  });
}



export async function issueChangeDetail(planId: string, type: string) {
  return request<API.ImileResponse<any>>('/issue-plan-change-log-record/issue-change-detail', {
    method: 'GET',
    params: {
      planId,
      type
    }
  });
}




export async function pagedSprint(
  params: {
    current?: number;
    pageSize?: number;
    status?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.SprintList>>('/issue-plan/page-plan', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}
export async function pagedSprintTree(
  params: {
    current?: number;
    pageSize?: number;
    status?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.SprintList>>('/issue-plan/page-plan-tree', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}

// 未规划需求分页查询
export async function pageUnplannedIssue(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request('/issue-plan/page-unplanned-issue', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}


/** 规划绑定需求 */
export async function addPlanIssue(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.SystemList>('/issue-plan/add-plan-issue', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}


/** 获取规划、需求的发布物料明细,如果返回了发布单id，则说明该载体已经提交过发布，需要隐藏提交按钮，并提示已创建发布单，无法重复创建 */
export async function getReleaseMaterial(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.PublishDetail>>('/issue-plan/get-release-material', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 创建正常发布 */
export async function createRelease(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/issue-plan/create-release', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

// /** 创建紧急发布 */
// export async function createEmergencyRelease(
//   body: {},
//   options?: { [key: string]: any },
// ) {
//   return request<API.ImileResponse<{}>>('/issue-plan/create-emergency-release', {
//     method: 'POST',
//     data: {
//       ...body
//     },
//     ...(options || {}),
//   });
// }


/** 根据系统id集合查规划 */
export async function listPlanBySystemIds(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/issue-plan/list-plan-by-systems', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 通过规划id集合查规划需求 */
export async function listIssueByPlanIds(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/issue-plan/list-issue-by-planIds', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 根据规划id集合查规划 */
export async function listPlanByIds(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/issue-plan/list-by-ids', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

export async function searchCnt(searchName: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-plan/search-cnt', {
    method: 'GET',
    params: {
      searchName: searchName
    },
    ...(options || {}),
  });
}
export async function lockOrUnlock(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-plan/lock-or-unlock', {
    method: 'POST',
    data: { ...body },
    ...(options || {}),
  });
}
export async function changeStatus(body: {}, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-plan/change-status', {
    method: 'POST',
    data: { ...body },
    ...(options || {}),
  });
}


// 未规划需求数分页查询
export async function pageUnplannedIssueTree(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request('/issue-plan/page-unplan-issue-tree', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}

/**获取sprint规划需求详情 */
export async function getIssueDetail(body: {}, options?: { [key: string]: any }) {
  return request('/issue-plan/issue-detail-v2', {
    method: 'POST',
    data: { ...body },
    ...(options || {}),
  });
}

/** 获取需求、迭代下的执行人员 */
export async function getExecutors(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/issue-plan/get-executors', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

export async function planStartEndRemind(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<{}>>('/issue-plan/remind-start-end-plan', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}



export async function email(planId: any, imgBase64Url: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue-plan/email', {
    method: 'POST',
    data: {
      planId: planId,
      imgBase64Url: imgBase64Url
    },
    ...(options || {}),
  });
}