// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';






/** 获取详情 */
export async function getTaskDetail(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.TaskDetail>>('/rd-task/detail?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除任务 */
export async function delTask(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/delete?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除任务 */
export async function restoreTask(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/restore?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

/**根据类型查询服务 */
export async function listServiceByServiceType(
  serviceType: number,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/system/relation/listServiceByServiceType', {
    method: 'POST',
    data: {
      serviceType: serviceType
    },
    ...(options || {}),
  });
}

/**根据服务查询分支 */
export async function listGitByService(
  systemName: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/git/list', {
    method: 'POST',
    data: {
      systemName: systemName
    },
    ...(options || {}),
  });
}

/**
 * 创建任务
 * @param body 
 * @param options 
 * @returns 
 */

export async function createTask(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/create', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/**
 * 
 * @param body 修改任务
 * @param options 
 * @returns 
 */
export async function updTask(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/update', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/**
 * 获取任务明细(查指定需求或规划或...下的任务)
 * @param body 
 * * 查询类型
 * 需求：queryType = issue
 * 规划：queryType = plan
 * *
 * 查询id
 * 需求：queryId = 需求id
 * 规划：queryId = 规划id
 * @param options 
 * @returns 
 */
export async function listParticulars(
  body: {
    queryType: string,
    queryId: string,
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/particulars', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function changeTaskProgress(
  body: {
    id: string,
    taskProgress: string,
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/change-task-progress', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function createBranch(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/service-git-branch/create-branch', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function getTaskServiceByIssueIds(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/get-task-service-by-issue-ids', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function listTaskServiceByIssueIds(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/list-task-service-by-issue-ids', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

// 未规划需求分页查询
export async function pagedTasks(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.TaskListItem>>('/rd-task/page', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}


export async function searchCnt(searchName: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/rd-task/search-cnt', {
    method: 'GET',
    params: {
      searchName: searchName
    },
    ...(options || {}),
  });
}

export async function updTaskRemark(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/upd-task-remark', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

/** 通过任务Id查需求记录 */
export async function getMaxRangeDateByTaskIds(taskIds: any[]) {
  return request<API.ImileResponse<any>>('/rd-task/get-maxRangeDate-by-taskIds', {
    method: 'POST',
    data: taskIds,
  });
}



