import { Avatar, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';

export type Props = {
  infos: any;
  showImg?: boolean;
  showCount?: number;
  isDisableTooltip?: boolean;
  /**图片大小 */
  imgSize?: 'large' | 'small' | 'default' | number;
};
const MyComponent: React.FC<Props> = (props) => {
  const totalInfos = props.infos?.length;
  const displayInfos = props.infos?.slice(0, props.showCount || 3);
  const remainingCount = totalInfos - displayInfos?.length;

  const getDisplayName = (name: string) => {
    if (!name) return 'N/A';
    // 定义正则表达式
    const chineseRegex = /[\u4e00-\u9fa5]/g; // 匹配所有的汉字
    const wordRegex = /(\w+)$/; // 匹配最后一个单词

    // 提取所有的汉字
    const chineseMatches = name.match(chineseRegex);

    if (chineseMatches) {
      const length = chineseMatches.length;
      if (length >= 2) {
        // 返回最后两个汉字
        return chineseMatches.slice(-2).join('');
      } else if (length === 1) {
        // 返回唯一的一个汉字
        return chineseMatches[0];
      }
    }

    // 如果没有汉字，则匹配最后一个单词
    const wordMatch = name.match(wordRegex);
    if (wordMatch) {
      return wordMatch[0];
    }

    // 如果都没有匹配到，则返回原字符串
    return name;
  };

  return (
    <div>
      {displayInfos?.map((item) => {
        if (!item) {
          return '';
        }
        return (
          <Space key={item?.userCode} wrap>
            {props.showImg && (
              <Tooltip title={props?.isDisableTooltip ? '' : item?.userName}>
                {item?.userAvatar && (
                  <Avatar
                    size={props?.imgSize || 'small'}
                    src={<img src={item?.userAvatar} alt="avatar" />}
                  />
                )}
                {!item?.userAvatar && (
                  <Avatar
                    size={props?.imgSize || 'small'}
                    style={{ backgroundColor: '#91b4e5' }}
                    icon={<UserOutlined />}
                    alt="avatar"
                  />
                )}
              </Tooltip>
            )}
            {!props.showImg && (
              <Tooltip title={props?.isDisableTooltip ? '' : item?.userName}>
                <Avatar
                  shape="square"
                  size={27}
                  icon={<span style={{ fontSize: 10 }}>{getDisplayName(item.userName)}</span>}
                  style={{ backgroundColor: 'rgb(79 153 248)', marginRight: 1 }}
                  alt="avatar"
                ></Avatar>
              </Tooltip>
            )}
          </Space>
        );
      })}
      {remainingCount > 0 && (
        <Tooltip
          title={props.infos
            ?.slice(props.showCount || 3)
            .map((item) => item?.userName)
            .join(', ')}
        >
          <span style={{ marginLeft: 4 }}>...</span>
        </Tooltip>
      )}
    </div>
  );
};

export default MyComponent;
