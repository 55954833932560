// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/home/jenkins/workspace/workspace/devops-web/src/models/currentSystem';
import model_2 from '/home/jenkins/workspace/workspace/devops-web/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'currentSystem', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
