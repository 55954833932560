import { Avatar, Mentions, Space, Tabs, TabsProps, Timeline, Tooltip, message } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { addComment, delCommonent, listIssueOpLog } from "@/services/rd/issues/api";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { useIntl, useModel, useRequest } from "@@/exports";
import { DeleteOutlined } from "@ant-design/icons";
import { listUsers } from "@/services/common/api";
import BraftEditor from "braft-editor";

export type Props = {
  id: any;
  relationType: string;
  commentReadOnly: boolean;
  showComment: boolean;
  // reloadId: any;
};
// 然后定义你的ref的类型，这通常是一个对象，包含你想暴露的方法
export interface TimeLineLogHandles {
  submitComment: () => void;
}
const MyComponent = forwardRef<TimeLineLogHandles, Props>((props, ref) => {

  const intl = useIntl()
  const { initialState, setInitialState } = useModel('@@initialState');
  const [logType, setLogType] = useState('CHANGE_STATUS');
  const [commentContent, setCommentContent] = useState('');
  const [stateFrom, setStateFrom] = useState({ editorState: BraftEditor.createEditorState(null) });
  const [stateTo, setStateTo] = useState({ editorState: BraftEditor.createEditorState(null) });


  const { data: userList, run: runUserList } = useRequest(listUsers, { manual: true })
  const { data: opLog, run: runOpLog } = useRequest((opType) => {
    const param = {
      relationType: props.relationType,
      relationId: props.id,
      opType: opType
    }
    return listIssueOpLog(param)
  }, { manual: true })


  const CommentClass = useEmotionCss(({ token }) => {
    return {
      '.ant-timeline-item-label': {
        width: '130px !important'
      },
      '.ant-timeline-item-tail': {
        insetInlineStart: '140px !important'
      },
      '.ant-timeline-item-head': {
        insetInlineStart: '140px !important'
      },
      '.ant-timeline-item-content': {
        insetInlineStart: '130px !important',
        width: 'calc(100% - 170px) !important'
      }
    }
  })

  const editorClass = useEmotionCss(({ token }) => {
    return {
      '.bf-content': {
        height: '100%'
      },
    }
  })


  const items: TabsProps['items'] = [
    {
      key: '',
      label: '全部记录',
      children: ''
    },
    {
      key: 'CHANGE_STATUS',
      label: '状态变更',
      children: ''
    },
    {
      key: 'COMMENT',
      label: props.showComment ? '评论' : '',
      children: ''
    },
  ];

  const coment = async () => {
    if (!commentContent) {
      message.error('评论不能为空')
      return
    }
    const param = {
      relationId: props.id,
      relationType: props.relationType,
      content: commentContent
    }
    const { status } = await addComment(param)
    if (status === 'success') {
      message.success('评论成功')
      setCommentContent('')
      runOpLog(logType)
    }

  }

  // 使用useImperativeHandle来向父组件暴露组件内部的方法
  useImperativeHandle(ref, () => ({
    submitComment: () => {
      if (commentContent) {
        coment();
      }
    }
  }));


  useEffect(() => {
    if (props.id) {
      runUserList();
      runOpLog(logType);
    }
  }, [props.id])


  return (
    <>
      <Tabs defaultActiveKey={logType} items={items} onChange={(key: string) => {
        setLogType(key)
        runOpLog(key);
      }} />
      <><Timeline
        key={Math.random()}
        className={CommentClass}
        mode={'left'}
        items={opLog?.map((item: { opTime: any; opLogRecords: any[]; }) => {
          return {
            label: item.opTime,
            children: item.opLogRecords.map((record: any, index) => {
              let s = [];
              let s1 = [];
              if (record.opContent.indexOf('Description') > 0 || record.opContent.indexOf('description') > 0) {
                s = record.opContent.split('from ')
                s1 = s[1]?.split(' to ')
              }
              return <>
                {s1.length > 0 ? (
                  <> {index + 1}. {s[0] + 'from '}
                    <div style={{ border: `1px solid  rgb(232 232 232)`, borderRadius: 5 }} >
                      <BraftEditor
                        readOnly={true}
                        controls={[]}
                        value={BraftEditor.createEditorState(s1[0])}
                        className={editorClass}
                      />
                    </div>

                    {' to '}
                    <div style={{ border: `1px solid  rgb(232 232 232)`, borderRadius: 5 }} >
                      <BraftEditor
                        readOnly={true}
                        controls={[]}
                        value={BraftEditor.createEditorState(s1[1])}
                        className={editorClass}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {index + 1}. {record.opContent}
                  </>
                )}
                {record.createUserCode === initialState?.currentUser.userName && record.opType === 'COMMENT' && !props.commentReadOnly && <a
                  style={{ marginLeft: 10 }}
                  onClick={async () => {
                    const { status } = await delCommonent(record.id)
                    if (status === 'success') {
                      message.success('success')
                      runOpLog('COMMENT')
                    }
                  }}
                >
                  <Tooltip title={intl.formatMessage({
                    id: "pages.common.delete",
                    defaultMessage: "删除"
                  })}>
                    <DeleteOutlined style={{ color: "#ff4d4f" }}
                    />
                  </Tooltip>
                </a >}<br /></>
            })
          }

        })}
      />
        {logType !== 'CHANGE_STATUS' && !props.commentReadOnly && < Mentions
          style={{ width: '80%' }}
          rows={3}
          value={commentContent}
          // placeholder="You can use @ to ref user here"
          placeholder="请输入评论内容"
          onChange={(value) => {
            setCommentContent(value)
          }}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode
          }))}
        />}
        {logType !== 'CHANGE_STATUS' && !props.commentReadOnly && < a
          style={{ marginLeft: 10 }}
          onClick={coment}
        >
          发表评论
        </a >}
      </>
    </>
  );
});

export default MyComponent;
