// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

// 分页查标签
export async function pageLabel(
    params: {
        current?: number;
        pageSize?: number;
        status?: any;
        searchKey?: any;
        labelType?: any;
    },
    options?: { [key: string]: any },
) {
    return request('/base/label/pageList', {
        method: 'POST',
        data: {
            currentPage: params.current,
            showCount: params.pageSize,
            ...params
        },
        ...(options || {}),
    });
}


/** 下拉接口v2 */
export async function selectListV2(labelType: any, options?: { [key: string]: any }) {
    return request('/base/label/selectList/v2?labelType=' + labelType, {
        method: 'GET',
        ...(options || {}),
    });
}

/** 新增标签 */
export async function addLabel(
    body: {},
    options?: { [key: string]: any },
) {
    return request('/base/label/add', {
        method: 'POST',
        data: {
            ...body
        },
        ...(options || {}),
    });
}

/** 修改标签 */
export async function updateLabel(
    body: {},
    options?: { [key: string]: any },
) {
    return request('/base/label/update', {
        method: 'POST',
        data: {
            ...body
        },
        ...(options || {}),
    });
}

/** 删除标签 */
export async function deleteLabel(
    body: {},
    options?: { [key: string]: any },
) {
    return request('/base/label/delete', {
        method: 'POST',
        data: {
            ...body
        },
        ...(options || {}),
    });
}

/** 状态切换 */
export async function switchStatus(
    body: {},
    options?: { [key: string]: any },
) {
    return request('/base/label/switchStatus', {
        method: 'POST',
        data: {
            ...body
        },
        ...(options || {}),
    });
}

/** 获取打标明细 */
export async function markingDetail(labelCode: any, options?: { [key: string]: any }) {
    return request('/base/label/marking-detail?labelCode=' + labelCode, {
        method: 'GET',
        ...(options || {}),
    });
}

/** 通过关联记录id删除关联记录 */
export async function delateRelation(id: number, options?: { [key: string]: any }) {
    return request('/base/label/delate-relation?id=' + id, {
        method: 'GET',
        ...(options || {}),
    });
}

/** 获取标签记录详情 */
export async function labelDetail(id: number, options?: { [key: string]: any }) {
    return request('/base/label/detail?id=' + id, {
        method: 'GET',
        ...(options || {}),
    });
}